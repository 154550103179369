import { Tabs } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useAppFeature } from "src/core/hooks/useAppFeature";
import { useCleanPriceConfiguratorState } from "src/core/hooks/useCleanPriceConfiguratorState";
import { applicationInsights } from "src/core/providers/TelemetryProvider/core/applicationInsights";
import { getPageViewEventByCCSection } from "src/core/providers/TelemetryProvider/utils/utils";
import { AppFeatureEnum, CostComparisonSectionTypeEnum } from "src/enums";
import { AdminPage } from "src/modules/AdminPage/AdminPage";
import { useCostComparisonModelStats } from "src/modules/CostComparisonManager/hooks/useCostComparisonModelStats";
import { useCostComparisonModelsTitle } from "src/modules/CostComparisonManager/hooks/useCostComparisonModelsTitle";
import { useCostComparisonTabs } from "src/modules/CostComparisonManager/hooks/useCostComparisonTabs";
import {
  costComparisonAppState,
  costComparisonModel1GlobalState,
  costComparisonModel2GlobalState,
} from "src/recoil/costExplosionAtoms";

export const CostComparisonManager: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [activeSectionKey, setActiveSectionKey] = useState<string>();

  const { t } = useTranslation("costExplosionComparison");

  useAppFeature(AppFeatureEnum.COSTCOMPARISONFEATURE);

  const { loading: cleanLoading } = useCleanPriceConfiguratorState();

  const [costComparisonAppStateData, setCostComparisonAppStateData] =
    useRecoilState(costComparisonAppState);

  const setModel1GlobalState = useSetRecoilState(
    costComparisonModel1GlobalState
  );
  const setModel2GlobalState = useSetRecoilState(
    costComparisonModel2GlobalState
  );

  useCostComparisonModelStats();

  const { appTitle } = useCostComparisonModelsTitle();

  const tabs = useCostComparisonTabs();

  useEffect(() => {
    setModel1GlobalState({
      modelNumber: "",
      isPackaging: true,
      modelOptionsGrouped: [],
      selectedGroupModels: [],
      selectedModelSkus: [],
      filteredSelectedGroupModels: [],
    });

    setModel2GlobalState({
      modelNumber: "",
      isPackaging: true,
      modelOptionsGrouped: [],
      selectedGroupModels: [],
      selectedModelSkus: [],
      filteredSelectedGroupModels: [],
    });

    setLoading(false);
  }, [setModel1GlobalState, setModel2GlobalState]);

  useEffect(() => {
    if (!activeSectionKey) {
      setActiveSectionKey(
        CostComparisonSectionTypeEnum[
          CostComparisonSectionTypeEnum.MODELSELECTOR1
        ]
      );

      applicationInsights.trackPageView("CC_SkuSel1");
    }
  }, [activeSectionKey]);

  const onChange = (key: string) => {
    if (key === "MODELSELECTOR1" || key === "MODELSELECTOR2") {
      if (!costComparisonAppStateData.selectingModelSkus) {
        setCostComparisonAppStateData((prevState) => {
          return { ...prevState, selectingModelSkus: true };
        });
      }
    } else {
      if (costComparisonAppStateData.selectingModelSkus) {
        setCostComparisonAppStateData((prevState) => {
          return { ...prevState, selectingModelSkus: false };
        });
      }
    }

    setActiveSectionKey(key);

    applicationInsights.trackPageView(
      getPageViewEventByCCSection(
        CostComparisonSectionTypeEnum[
          key as keyof typeof CostComparisonSectionTypeEnum
        ]
      )
    );
  };

  return (
    <AdminPage
      activePage="cost-comparison"
      pageTitle={t("title.costComparisonTitle")}
      centerTitle={appTitle}
      contentLoading={loading || cleanLoading}
    >
      <Tabs
        defaultActiveKey={activeSectionKey}
        items={tabs}
        onChange={onChange}
        type="card"
        className="tabs-with-borders"
        style={{ width: "100%", paddingBottom: 50 }}
      />
    </AdminPage>
  );
};
