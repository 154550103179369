import { ColumnsType } from "antd/es/table";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { TextWithLink } from "src/core/components/TextWithLink";
import { formatNumber } from "src/core/utils/format";
import { getGroupedCellRowSpan } from "src/core/utils/table";
import { ValueWithCurrency } from "src/modules/ValueWithCurrency/ValueWithCurrency";
import { CostByFatherDataItem } from "src/types/costExplosion";

export function useFatherCodeDataItemColumns(
  dataItems: CostByFatherDataItem[]
) {
  const { t } = useTranslation("costExplosionComparison");

  const getTableColumns = useCallback(() => {
    const columns: ColumnsType<CostByFatherDataItem> = [
      {
        title: t("tableColumn.father"),
        key: "father_column",
        align: "center",
        width: 140,
        onCell: (record: CostByFatherDataItem, index: number | undefined) => ({
          rowSpan: getGroupedCellRowSpan(index ?? 0, record, dataItems, [
            "costTypeMapping",
            "father",
          ]),
        }),
        render: (item: CostByFatherDataItem) =>
          (item.rowType ?? null) !== null ? item.costTypeMapping : item.father,
      },
      {
        title: t("tableColumn.fatherCode"),
        key: "fatherCode_column",
        align: "center",
        width: 120,
        render: (item: CostByFatherDataItem) => (
          <TextWithLink
            label={item.fatherCode}
            href={item.fatherCode3dSplm21xUrl}
          />
        ),
      },
      {
        title: t("tableColumn.fullCost"),
        key: "fullCost_column",
        align: "center",
        width: 80,
        render: (item: CostByFatherDataItem) => (
          <ValueWithCurrency
            value={item.fullCost}
            currencyCode={item.currencyCode}
          />
        ),
      },
      {
        title: t("tableColumn.directCost"),
        key: "directCost_column",
        align: "center",
        width: 70,
        render: (item: CostByFatherDataItem) => (
          <ValueWithCurrency
            value={item.directCost}
            currencyCode={item.currencyCode}
          />
        ),
      },
      {
        title: t("tableColumn.variableCost"),
        key: "variableCost_column",
        align: "center",
        width: 70,
        render: (item: CostByFatherDataItem) => (
          <ValueWithCurrency
            value={item.variableCost}
            currencyCode={item.currencyCode}
          />
        ),
      },
      {
        title: t("tableColumn.dm"),
        key: "dm_column",
        align: "center",
        width: 60,
        render: (item: CostByFatherDataItem) => (
          <ValueWithCurrency value={item.dm} currencyCode={item.currencyCode} />
        ),
      },
      {
        title: t("tableColumn.dl"),
        key: "dl_column",
        align: "center",
        width: 60,
        render: (item: CostByFatherDataItem) => (
          <ValueWithCurrency value={item.dl} currencyCode={item.currencyCode} />
        ),
      },
      {
        title: t("tableColumn.sc"),
        key: "sc_column",
        align: "center",
        width: 60,
        render: (item: CostByFatherDataItem) => (
          <ValueWithCurrency value={item.sc} currencyCode={item.currencyCode} />
        ),
      },
      {
        title: t("tableColumn.fov"),
        key: "fov_column",
        align: "center",
        width: 60,
        render: (item: CostByFatherDataItem) => (
          <ValueWithCurrency
            value={item.fov}
            currencyCode={item.currencyCode}
          />
        ),
      },
      {
        title: t("tableColumn.fof1"),
        key: "fof1_column",
        align: "center",
        width: 60,
        render: (item: CostByFatherDataItem) => (
          <ValueWithCurrency
            value={item.fof1}
            currencyCode={item.currencyCode}
          />
        ),
      },
      {
        title: t("tableColumn.fof2"),
        key: "fof2_column",
        align: "center",
        width: 60,
        render: (item: CostByFatherDataItem) => (
          <ValueWithCurrency
            value={item.fof2}
            currencyCode={item.currencyCode}
          />
        ),
      },
      {
        title: t("tableColumn.mo"),
        key: "mo_column",
        align: "center",
        width: 60,
        render: (item: CostByFatherDataItem) => (
          <ValueWithCurrency value={item.mo} currencyCode={item.currencyCode} />
        ),
      },
      {
        title: t("tableColumn.dc"),
        key: "dc_column",
        align: "center",
        width: 60,
        render: (item: CostByFatherDataItem) => (
          <ValueWithCurrency value={item.dc} currencyCode={item.currencyCode} />
        ),
      },
      {
        title: t("tableColumn.pureTime"),
        key: "pureTime_column",
        align: "center",
        width: 70,
        render: (item: CostByFatherDataItem) => formatNumber(item.pureTime),
      },
    ];

    return columns;
  }, [dataItems, t]);

  return {
    getTableColumns: getTableColumns,
  };
}
