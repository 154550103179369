import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { calculatePropertySum } from "src/core/utils/array";
import { TableRowTypeEnum } from "src/enums";
import { CostExplosionDataItem } from "src/types/costExplosion";

export function useCostExplosionDataItemData(
  dataItems: CostExplosionDataItem[]
) {
  const [processed, setProcessed] = useState(false);
  const [result, setResult] = useState<CostExplosionDataItem[]>([]);

  const { t } = useTranslation("costExplosionComparison");

  useEffect(() => {
    (async () => {
      if (dataItems.length > 0) {
        let finalDataItems: CostExplosionDataItem[] = [
          {
            rowKey: "summary-row-1",

            currencyCode: dataItems[0].currencyCode,
            rowType: TableRowTypeEnum.SUMMARYROW,

            costTypeMapping: t("label.totalCost") ?? "",

            fullCost: calculatePropertySum(dataItems, "fullCost"),
            directCost: calculatePropertySum(dataItems, "directCost"),
            variableCost: calculatePropertySum(dataItems, "variableCost"),

            dm: calculatePropertySum(dataItems, "dm"),
            dl: calculatePropertySum(dataItems, "dl"),
            sc: calculatePropertySum(dataItems, "sc"),
            fov: calculatePropertySum(dataItems, "fov"),
            fof1: calculatePropertySum(dataItems, "fof1"),
            fof2: calculatePropertySum(dataItems, "fof2"),
            mo: calculatePropertySum(dataItems, "mo"),
            dc: calculatePropertySum(dataItems, "dc"),

            pureTime: calculatePropertySum(dataItems, "pureTime"),
          },
        ];

        dataItems.forEach((item: CostExplosionDataItem, index) => {
          const existingSubSummaryRow = finalDataItems.find(
            (x) =>
              x.rowType === TableRowTypeEnum.SUBSUMMARYROW &&
              x.costTypeMapping === item.costTypeMapping
          );

          if (!existingSubSummaryRow) {
            const recordsToWork = dataItems.filter(
              (x) => x.costTypeMapping === item.costTypeMapping
            );

            finalDataItems.push({
              rowKey: `sub-summary-${index}`,

              costTypeMapping: item.costTypeMapping,

              fullCost: calculatePropertySum(recordsToWork, "fullCost"),
              directCost: calculatePropertySum(recordsToWork, "directCost"),
              variableCost: calculatePropertySum(recordsToWork, "variableCost"),

              dm: calculatePropertySum(recordsToWork, "dm"),
              dl: calculatePropertySum(recordsToWork, "dl"),
              sc: calculatePropertySum(recordsToWork, "sc"),
              fov: calculatePropertySum(recordsToWork, "fov"),
              fof1: calculatePropertySum(recordsToWork, "fof1"),
              fof2: calculatePropertySum(recordsToWork, "fof2"),
              mo: calculatePropertySum(recordsToWork, "mo"),
              dc: calculatePropertySum(recordsToWork, "dc"),

              pureTime: calculatePropertySum(recordsToWork, "pureTime"),

              currencyCode: dataItems[0].currencyCode,
              rowType: TableRowTypeEnum.SUBSUMMARYROW,
            });
          }

          finalDataItems.push({ ...item, rowKey: `row-key-${index}` });
        });

        setResult(finalDataItems);
      } else {
        setResult([]);
      }

      setProcessed(true);
    })();
  }, [dataItems, t]);

  return {
    processed: processed,
    processedData: result,
  };
}
