import { Drawer, Skeleton, Space } from "antd";
import Title from "antd/es/typography/Title";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { useAuth } from "src/core/hooks/useAuth";
import { eventsService } from "src/core/services/priceConfiguratorServices/eventsService";
import { priceConfiguratorSummaryService } from "src/core/services/priceConfiguratorServices/priceConfiguratorSummaryService";
import { CostSummaryTable } from "src/modules/PriceConfiguratorManager/components/SummaryDrawer/components/CostSummaryTable";
import { DataSummaryTable } from "src/modules/PriceConfiguratorManager/components/SummaryDrawer/components/DataSummaryTable";
import { MergeSummaryTable } from "src/modules/PriceConfiguratorManager/components/SummaryDrawer/components/MergeSummaryTable";
import { PriceConfiguratorInfoBlock } from "src/modules/PriceConfiguratorManager/components/SummaryDrawer/components/PriceConfiguratorInfoBlock";
import {
  currentPriceConfiguratorState,
  currentUserState,
} from "src/recoil/atoms";
import {
  CurrencyData,
  PriceConfiguratorCostSummaryData,
} from "src/types/priceConfigurator";

type Props = {
  openDrawer: boolean;
  setOpenDrawer: (value: boolean) => void;
  onClose?: () => void;
};

export const SummaryDrawer = (props: Props) => {
  const { openDrawer, setOpenDrawer, onClose } = props;

  const currentPriceConfigurator = useRecoilValue(
    currentPriceConfiguratorState
  );
  const currentUser = useRecoilValue(currentUserState);

  const { accessToken, success, authLoaded } = useAuth();

  const [currentCurrency, setCurrentCurrency] = useState<CurrencyData>();

  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [refreshCompleted, setRefreshCompleted] = useState<boolean>(false);
  const [summaryData, setSummaryData] =
    useState<PriceConfiguratorCostSummaryData>();

  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      if (
        authLoaded &&
        success &&
        currentPriceConfigurator &&
        currentPriceConfigurator.id > 0 &&
        openDrawer
      ) {
        setLoadingData(true);

        if (currentPriceConfigurator.currencies) {
          const existingCurrencyData = currentPriceConfigurator.currencies.find(
            (currency: CurrencyData) =>
              currency.currencyCode === currentUser.currency?.currencyCode
          );

          setCurrentCurrency(existingCurrencyData);
        }

        await eventsService.processDataRefreshEvent(
          accessToken,
          currentPriceConfigurator.id
        );

        setRefreshCompleted(true);

        const response =
          await priceConfiguratorSummaryService.fetchPriceConfiguratorSummary(
            accessToken,
            currentPriceConfigurator.id
          );

        setSummaryData(response);
        setLoadingData(false);
      }
    })();
  }, [
    accessToken,
    authLoaded,
    currentPriceConfigurator,
    currentUser.currency?.currencyCode,
    openDrawer,
    success,
  ]);

  const onCloseDrawer = () => {
    setOpenDrawer(false);
    setRefreshCompleted(false);
    setSummaryData({
      costDetailSummary: [],
      costElementSummary: [],
      dataSummary: undefined,
    });

    if (onClose) {
      onClose();
    }
  };

  return (
    <Drawer
      placement="right"
      onClose={onCloseDrawer}
      open={openDrawer}
      styles={{
        header: { padding: "3px 8px 3px 8px" },
        body: { paddingTop: 5 },
      }}
    >
      <Skeleton
        loading={currentPriceConfigurator.id === 0}
        style={{ padding: 10 }}
        active
      >
        <Space direction="vertical" className="full-width-space" size={0}>
          {refreshCompleted && (
            <PriceConfiguratorInfoBlock
              priceConfiguratorId={currentPriceConfigurator.id}
            />
          )}
          {!loadingData && (
            <Title
              level={5}
              style={{
                fontSize: 14,
                marginTop: 15,
                marginBottom: 0,
                textAlign: "left",
              }}
            >
              {currentUser?.currency?.currencyCode === "EUR"
                ? currentUser?.currency?.currencyCode
                : `${currentUser?.currency?.currencyCode} (/EUR = ${
                    currentCurrency?.rate ?? "##.##"
                  })`}
            </Title>
          )}
          <Space direction="vertical" className="full-width-space">
            <CostSummaryTable
              data={summaryData?.costElementSummary ?? []}
              labelColumnTitle={t("label.costElement")}
              valueColumnTitle={`${t("label.cost")} (${
                currentUser.currency?.currencyCode
              })`}
              dataRefreshing={loadingData}
            />
            <CostSummaryTable
              data={summaryData?.costDetailSummary ?? []}
              labelColumnTitle={t("label.costDetail")}
              valueColumnTitle={`${t("label.cost")} (${
                currentUser.currency?.currencyCode
              })`}
              dataRefreshing={loadingData}
            />
            {summaryData?.dataSummary && (
              <DataSummaryTable
                data={summaryData?.dataSummary ?? []}
                labelColumnTitle={t("label.adjustedFxRate")}
                firstValueColumnTitle={t("label.mi")}
                secondValueColumnTitle={t("label.op")}
                dataRefreshing={loadingData}
              />
            )}
            {summaryData?.mergeSummary && (
              <MergeSummaryTable
                data={summaryData?.mergeSummary ?? []}
                tableTitle={t("title.pacaMergeSources")}
                dataRefreshing={loadingData}
              />
            )}
          </Space>
        </Space>
      </Skeleton>
    </Drawer>
  );
};
