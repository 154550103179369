import { Typography } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { formatNumber } from "src/core/utils/format";
import { textWithSuffix } from "src/core/utils/text";
import { MadeInOriginCalculationStatsItem } from "src/types/priceConfigurator";

type Props = {
  dataItems: MadeInOriginCalculationStatsItem[];
  dataRefreshing?: boolean;
};

const { Text } = Typography;

export const CalculationTable = (props: Props) => {
  const { dataItems, dataRefreshing } = props;

  const { t } = useTranslation();

  useEffect(() => {
    (async () => {})();
  }, []);

  const columns: ColumnsType<MadeInOriginCalculationStatsItem> = [
    {
      key: "made_in_origin_calculation_type_column",
      width: "33%",
      render: (item: MadeInOriginCalculationStatsItem) => (
        <Text
          style={{
            textAlign: "left",
            fontWeight: 600,
          }}
        >
          {t(`label.madeInOriginCalculationStatsType_${item.type}`)}
        </Text>
      ),
    },
    {
      title: t("tableColumn.costKoInEuros"),
      key: "calculation_costKoInEuros_column",
      align: "center",
      width: "33%",
      render: (item: MadeInOriginCalculationStatsItem) => (
        <Text style={{ fontWeight: 600 }}>
          {item.valueInPercents
            ? textWithSuffix(item.value, "%")
            : formatNumber(item.value)}
        </Text>
      ),
    },
    {
      title: t("tableColumn.costKoInEurosNewEr"),
      key: "calculation_costKoInEurosNewEr_column",
      align: "center",
      width: "33%",
      render: (item: MadeInOriginCalculationStatsItem) => (
        <Text style={{ fontWeight: 600 }}>
          {item.valueInPercents
            ? textWithSuffix(item.valueNewEr, "%")
            : formatNumber(item.valueNewEr)}
        </Text>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Table
        columns={columns}
        loading={dataRefreshing}
        dataSource={dataItems}
        pagination={false}
        rowKey={(record) => `${record.type}`}
        scroll={{ x: "max-content" }}
        bordered={true}
        size={"small"}
      />
    </React.Fragment>
  );
};
