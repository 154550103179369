import { ColumnsType } from "antd/es/table";
import { TrophyOutlined } from "@ant-design/icons";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ValueWithCurrency } from "src/modules/ValueWithCurrency/ValueWithCurrency";
import { ModelSkuSelectionItemData } from "src/types/costExplosion";

export function useModelSkuSelectionItemColumns() {
  const { t } = useTranslation("costExplosionComparison");

  const getTableColumns = useCallback(() => {
    const columns: ColumnsType<ModelSkuSelectionItemData> = [
      {
        title: t("tableColumn.state"),
        key: "model_state",
        align: "center",
        width: 50,
        render: (item: ModelSkuSelectionItemData) => item.isReference ? <TrophyOutlined /> : null,
      },
      {
        title: t("tableColumn.model"),
        key: "model_number",
        align: "center",
        width: 100,
        render: (item: ModelSkuSelectionItemData) => item.modelNumber,
      },
      {
        title: t("tableColumn.valType"),
        key: "val_type",
        align: "center",
        width: 120,
        render: (item: ModelSkuSelectionItemData) => item.valType,
      },
      {
        title: t("tableColumn.costLevel"),
        key: "cost_level",
        align: "center",
        width: 80,
        render: (item: ModelSkuSelectionItemData) =>
          (item.costLevel ?? null) !== null
            ? t(`enum.costLevel_${item.costLevel}`)
            : undefined,
      },
      {
        title: t("tableColumn.madeIn"),
        key: "made_in",
        align: "center",
        width: 80,
        render: (item: ModelSkuSelectionItemData) => item.madeIn,
      },
      {
        title: t("tableColumn.collection"),
        key: "collection",
        align: "center",
        width: 80,
        render: (item: ModelSkuSelectionItemData) => item.collection,
      },
      {
        title: t("tableColumn.material"),
        key: "material",
        align: "center",
        width: 80,
        render: (item: ModelSkuSelectionItemData) => item.material,
      },
      {
        title: t("tableColumn.modelRelease"),
        key: "model_release",
        align: "center",
        width: 80,
        render: (item: ModelSkuSelectionItemData) => item.release,
      },
      {
        title: t("tableColumn.skuRelease"),
        key: "skuRelease_column",
        align: "center",
        width: 80,
        render: (item: ModelSkuSelectionItemData) => item.skuRelease,
      },
      {
        title: t("tableColumn.mm"),
        key: "mm_column",
        align: "center",
        width: 60,
        render: (item: ModelSkuSelectionItemData) => item.mm,
      },
      {
        title: t("tableColumn.lensMaterialDetail"),
        key: "lensMaterialDetail_column",
        align: "center",
        width: 80,
        render: (item: ModelSkuSelectionItemData) => item.lensMaterialDetail,
      },
      {
        title: t("tableColumn.lensMaterial"),
        key: "lensMaterial_column",
        align: "center",
        width: 80,
        render: (item: ModelSkuSelectionItemData) => item.lensMaterial,
      },
      {
        title: t("tableColumn.lensType"),
        key: "lensType_column",
        align: "center",
        width: 80,
        render: (item: ModelSkuSelectionItemData) => item.lensType,
      },
      {
        title: t("tableColumn.photochromic"),
        key: "photochromic_column",
        align: "center",
        width: 90,
        render: (item: ModelSkuSelectionItemData) => item.photochromic,
      },
      {
        title: t("tableColumn.styleName"),
        key: "styleName_column",
        align: "center",
        width: 90,
        render: (item: ModelSkuSelectionItemData) => item.styleName,
      },
      {
        title: t("tableColumn.marketingTheme"),
        key: "marketingTheme_column",
        align: "center",
        width: 90,
        render: (item: ModelSkuSelectionItemData) => item.marketingTheme,
      },
      {
        title: t("tableColumn.marketingStory"),
        key: "marketingStory_column",
        align: "center",
        width: 90,
        render: (item: ModelSkuSelectionItemData) => item.marketingStory,
      },
      {
        title: t("tableColumn.fullCost"),
        key: "full_cost",
        align: "center",
        width: 80,
        render: (item: ModelSkuSelectionItemData) => (
          <ValueWithCurrency
            value={item.fullCost}
            currencyCode={item.currencyCode}
          />
        ),
      },
      {
        title: t("tableColumn.frameFc"),
        key: "frame_fc",
        align: "center",
        width: 80,
        render: (item: ModelSkuSelectionItemData) => (
          <ValueWithCurrency
            value={item.frameFc}
            currencyCode={item.currencyCode}
          />
        ),
      },
      {
        title: t("tableColumn.lensFc"),
        key: "lens_fc",
        align: "center",
        width: 80,
        render: (item: ModelSkuSelectionItemData) => (
          <ValueWithCurrency
            value={item.lensFc}
            currencyCode={item.currencyCode}
          />
        ),
      },
      {
        title: t("tableColumn.pkgFc"),
        key: "pkg_fc",
        align: "center",
        width: 80,
        render: (item: ModelSkuSelectionItemData) => (
          <ValueWithCurrency
            value={item.pkgFc}
            currencyCode={item.currencyCode}
          />
        ),
      },
      {
        title: t("tableColumn.directCost"),
        key: "direct_cost",
        align: "center",
        width: 80,
        render: (item: ModelSkuSelectionItemData) => (
          <ValueWithCurrency
            value={item.directCost}
            currencyCode={item.currencyCode}
          />
        ),
      },
      {
        title: t("tableColumn.frameDc"),
        key: "frameDc_column",
        align: "center",
        width: 80,
        render: (item: ModelSkuSelectionItemData) => (
          <ValueWithCurrency
            value={item.frameDc}
            currencyCode={item.currencyCode}
          />
        ),
      },
      {
        title: t("tableColumn.lensDc"),
        key: "lensDc_column",
        align: "center",
        width: 80,
        render: (item: ModelSkuSelectionItemData) => (
          <ValueWithCurrency
            value={item.lensDc}
            currencyCode={item.currencyCode}
          />
        ),
      },
      {
        title: t("tableColumn.pkgDc"),
        key: "pkgDc_column",
        align: "center",
        width: 80,
        render: (item: ModelSkuSelectionItemData) => (
          <ValueWithCurrency
            value={item.pkgDc}
            currencyCode={item.currencyCode}
          />
        ),
      },
      {
        title: t("tableColumn.dm"),
        key: "dm_column",
        align: "center",
        width: 80,
        render: (item: ModelSkuSelectionItemData) => (
          <ValueWithCurrency value={item.dm} currencyCode={item.currencyCode} />
        ),
      },
      {
        title: t("tableColumn.dl"),
        key: "dl_column",
        align: "center",
        width: 80,
        render: (item: ModelSkuSelectionItemData) => (
          <ValueWithCurrency value={item.dl} currencyCode={item.currencyCode} />
        ),
      },
      {
        title: t("tableColumn.sc"),
        key: "sc_column",
        align: "center",
        width: 80,
        render: (item: ModelSkuSelectionItemData) => (
          <ValueWithCurrency value={item.sc} currencyCode={item.currencyCode} />
        ),
      },
      {
        title: t("tableColumn.fov"),
        key: "fov_column",
        align: "center",
        width: 80,
        render: (item: ModelSkuSelectionItemData) => (
          <ValueWithCurrency
            value={item.fov}
            currencyCode={item.currencyCode}
          />
        ),
      },
      {
        title: t("tableColumn.fof1"),
        key: "fof1_column",
        align: "center",
        width: 80,
        render: (item: ModelSkuSelectionItemData) => (
          <ValueWithCurrency
            value={item.fof1}
            currencyCode={item.currencyCode}
          />
        ),
      },
      {
        title: t("tableColumn.fof2"),
        key: "fof2_column",
        align: "center",
        width: 80,
        render: (item: ModelSkuSelectionItemData) => (
          <ValueWithCurrency
            value={item.fof2}
            currencyCode={item.currencyCode}
          />
        ),
      },
      {
        title: t("tableColumn.mo"),
        key: "mo_column",
        align: "center",
        width: 80,
        render: (item: ModelSkuSelectionItemData) => (
          <ValueWithCurrency value={item.mo} currencyCode={item.currencyCode} />
        ),
      },
      {
        title: t("tableColumn.dc"),
        key: "dc_column",
        align: "center",
        width: 80,
        render: (item: ModelSkuSelectionItemData) => (
          <ValueWithCurrency value={item.dc} currencyCode={item.currencyCode} />
        ),
      },
    ];

    return columns;
  }, [t]);

  return {
    getTableColumns: getTableColumns,
  };
}
