import { Space } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useAuth } from "src/core/hooks/useAuth";
import { acetateService } from "src/core/services/costExplosionServices/acetateService";
import { useAcetateDataItemData } from "src/modules/CostExplosionManager/tabs/AcetateTab/hooks/useAcetateDataItemData";
import { useAcetateDataItemColumns } from "src/modules/CostExplosionManager/tabs/AcetateTab/hooks/useAcetateDetailTableColumns";
import { TableColumnsSelector } from "src/modules/TableColumnsSelector/TableColumnsSelector";
import { appParamsState } from "src/recoil/atoms";
import {
  costExplosionAppState,
  costExplosionGlobalState,
} from "src/recoil/costExplosionAtoms";
import { AcetateDataItem } from "src/types/costExplosion";


export const AcetateDetailsTable = () => {
    const [tableLoading, setTableLoading] = useState(false);

    const { accessToken, success, authLoaded } = useAuth();

    const [dataItems, setDataItems] = useState<AcetateDataItem[]>([]);

    const appParams = useRecoilValue(appParamsState);

    const costExplosionAppStateData = useRecoilValue(costExplosionAppState);

    const costExplosionState = useRecoilValue(costExplosionGlobalState);

    const { processed, processedData } = useAcetateDataItemData(dataItems);

    const { getTableColumns } = useAcetateDataItemColumns(processedData);

    const [tableColumns, setTableColumns] = useState<ColumnsType<AcetateDataItem>>([]);

    const [filteredTableColumns, setFilteredTableColumns] = useState<
    ColumnsType<AcetateDataItem>
  >([]);

    useEffect(() => {
        (async () => {
        setTableColumns(getTableColumns());
        setFilteredTableColumns(getTableColumns());
        })();
    }, [getTableColumns]);

    useEffect(() => {
        (async () => {
          if (authLoaded && success) {
            if (!costExplosionAppStateData.selectingModelSku) {
              if (
                costExplosionState.selectedModelSkus &&
                costExplosionState.selectedModelSkus?.length > 0
              ) {
                setTableLoading(true);

                let skusList: string[] = [];

                costExplosionState.selectedModelSkus.forEach((m) => {
                  if (m.sku) {
                    skusList.push(m.sku);
                  }
                });

                if (skusList.length > 0) {
                  const response = await acetateService.fetchAcetateDetailsDataItems(
                    accessToken,
                    costExplosionState.modelNumber ?? "",
                    skusList
                  );
                setDataItems(response);
                }

                setTableLoading(false);
              }
            }
          }
        })();
      }, [
        accessToken,
        authLoaded,
        costExplosionAppStateData.selectingModelSku,
        costExplosionState.modelNumber,
        costExplosionState.selectedModelSkus,
        success,
      ]);

    return (
    <Space direction="vertical" size={20} style={{ width: "100%" }}>
        <TableColumnsSelector
            baseColumns={tableColumns as []}
            unselectedColumnKeys={[]}
            setTableColumns={(columnsList) => {
                setFilteredTableColumns(columnsList as ColumnsType<AcetateDataItem>);//adapt  to acetate
            }}
        />
        <Table
            columns={filteredTableColumns}
            loading={tableLoading || !processed}
            dataSource={processedData}
            pagination={false}
            rowKey={({ rowKey }) => `${rowKey}`}
            sticky={true}
            scroll={{ x: "max-content", y: 480 }}
            style={{ width: appParams.contentWidth - 60 }}
            rowClassName={(row) =>
            (row.rowType ?? null) !== null ? `row-type-class-${row.rowType}` : ""
            }
            size={"small"}
            bordered
        />
    </Space>
    )
}
