import { ColumnsType } from "antd/es/table";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { getGroupedCellRowSpan } from "src/core/utils/table";
import { TableHeaderCellWithModelNumber } from "src/modules/CostComparisonManager/components/TableHeaderCellWithModelNumber";
import { ValueWithCurrency } from "src/modules/ValueWithCurrency/ValueWithCurrency";
import { FatherComparisonItemData } from "src/types/costComparison";

export function useFatherComparisonItemDataColumns(
  dataItems: FatherComparisonItemData[]
) {
  const { t } = useTranslation("costExplosionComparison");

  const getTableColumns = useCallback(() => {
    const columns: ColumnsType<FatherComparisonItemData> = [
      {
        title: t("tableColumn.father"),
        key: "father_column",
        align: "center",
        width: 120,
        onCell: (
          item: FatherComparisonItemData,
          index: number | undefined
        ) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
          rowSpan: getGroupedCellRowSpan(index ?? 0, item, dataItems, [
            "costTypeMapping",
            "father",
          ]),
        }),
        render: (item: FatherComparisonItemData) =>
          (item.rowType ?? null) !== null ? item.costTypeMapping : item.father,
      },
      {
        title: " ",
        key: "separator1_column",
        width: 1,
        onCell: (
          item: FatherComparisonItemData,
          index: number | undefined
        ) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell none-padding-cell left-right-border-dashed"
            : "none-padding-cell left-right-border-dashed",
        }),
        render: () => undefined,
      },
      /*-----------------  M1  ----------------------------------------------*/
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.fullCost")}
            number={1}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
          />
        ),
        key: "fullCost_column",
        align: "center",
        width: 90,
        onCell: (
          item: FatherComparisonItemData,
          index: number | undefined
        ) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
        }),
        render: (item: FatherComparisonItemData) => (
          <ValueWithCurrency
            value={item.m1FullCost}
            currencyCode={item.currencyCode}
            showSymbolIfNull={"-"}
          />
        ),
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.directCost")}
            number={1}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
          />
        ),
        key: "directCost_column",
        align: "center",
        width: 90,
        onCell: (
          item: FatherComparisonItemData,
          index: number | undefined
        ) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
        }),
        render: (item: FatherComparisonItemData) => (
          <ValueWithCurrency
            value={item.m1DirectCost}
            currencyCode={item.currencyCode}
            showSymbolIfNull={"-"}
          />
        ),
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.dm")}
            number={1}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
          />
        ),
        key: "dm_column",
        align: "center",
        width: 70,
        onCell: (
          item: FatherComparisonItemData,
          index: number | undefined
        ) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
        }),
        render: (item: FatherComparisonItemData) => (
          <ValueWithCurrency
            value={item.m1Dm}
            currencyCode={item.currencyCode}
            showSymbolIfNull={"-"}
          />
        ),
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.dl")}
            number={1}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
          />
        ),
        key: "dl_column",
        align: "center",
        width: 70,
        onCell: (
          item: FatherComparisonItemData,
          index: number | undefined
        ) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
        }),
        render: (item: FatherComparisonItemData) => (
          <ValueWithCurrency
            value={item.m1Dl}
            currencyCode={item.currencyCode}
            showSymbolIfNull={"-"}
          />
        ),
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.sc")}
            number={1}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
          />
        ),
        key: "sc_column",
        align: "center",
        width: 70,
        onCell: (
          item: FatherComparisonItemData,
          index: number | undefined
        ) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
        }),
        render: (item: FatherComparisonItemData) => (
          <ValueWithCurrency
            value={item.m1Sc}
            currencyCode={item.currencyCode}
            showSymbolIfNull={"-"}
          />
        ),
      },
      {
        title: " ",
        key: "separator2_column",
        width: 1,
        onCell: (
          item: FatherComparisonItemData,
          index: number | undefined
        ) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell none-padding-cell left-right-border-dashed"
            : "none-padding-cell left-right-border-dashed",
        }),
        render: () => undefined,
      },
      /*-----------------  M2  ----------------------------------------------*/
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.fullCost")}
            number={2}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
          />
        ),
        key: "fullCost_column",
        align: "center",
        width: 90,
        onCell: (
          item: FatherComparisonItemData,
          index: number | undefined
        ) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
        }),
        render: (item: FatherComparisonItemData) => (
          <ValueWithCurrency
            value={item.m2FullCost}
            currencyCode={item.currencyCode}
            showSymbolIfNull={"-"}
          />
        ),
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.directCost")}
            number={2}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
          />
        ),
        key: "directCost_column",
        align: "center",
        width: 90,
        onCell: (
          item: FatherComparisonItemData,
          index: number | undefined
        ) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
        }),
        render: (item: FatherComparisonItemData) => (
          <ValueWithCurrency
            value={item.m2DirectCost}
            currencyCode={item.currencyCode}
            showSymbolIfNull={"-"}
          />
        ),
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.dm")}
            number={2}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
          />
        ),
        key: "dm_column",
        align: "center",
        width: 70,
        onCell: (
          item: FatherComparisonItemData,
          index: number | undefined
        ) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
        }),
        render: (item: FatherComparisonItemData) => (
          <ValueWithCurrency
            value={item.m2Dm}
            currencyCode={item.currencyCode}
            showSymbolIfNull={"-"}
          />
        ),
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.dl")}
            number={2}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
          />
        ),
        key: "dl_column",
        align: "center",
        width: 70,
        onCell: (
          item: FatherComparisonItemData,
          index: number | undefined
        ) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
        }),
        render: (item: FatherComparisonItemData) => (
          <ValueWithCurrency
            value={item.m2Dl}
            currencyCode={item.currencyCode}
            showSymbolIfNull={"-"}
          />
        ),
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.sc")}
            number={2}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
          />
        ),
        key: "sc_column",
        align: "center",
        width: 70,
        onCell: (
          item: FatherComparisonItemData,
          index: number | undefined
        ) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
        }),
        render: (item: FatherComparisonItemData) => (
          <ValueWithCurrency
            value={item.m2Sc}
            currencyCode={item.currencyCode}
            showSymbolIfNull={"-"}
          />
        ),
      },
      {
        title: " ",
        key: "separator3_column",
        width: 1,
        onCell: (
          item: FatherComparisonItemData,
          index: number | undefined
        ) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell none-padding-cell left-right-border-dashed"
            : "none-padding-cell left-right-border-dashed",
        }),
        render: () => undefined,
      },
      /*-----------------  M2 - M1  ----------------------------------------------*/
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.fullCost")}
            number={2}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
            compareModelWith={1}
          />
        ),
        key: "fullCost_column",
        align: "center",
        width: 90,
        onCell: (
          item: FatherComparisonItemData,
          index: number | undefined
        ) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
          rowSpan: getGroupedCellRowSpan(index ?? 0, item, dataItems, [
            "cumulatedGroupId",
          ]),
        }),
        render: (item: FatherComparisonItemData) => (
          <ValueWithCurrency
            value={item.deltaFullCost}
            currencyCode={item.currencyCode}
            showSymbolIfNull={"-"}
          />
        ),
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.directCost")}
            number={2}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
            compareModelWith={1}
          />
        ),
        key: "directCost_column",
        align: "center",
        width: 90,
        onCell: (
          item: FatherComparisonItemData,
          index: number | undefined
        ) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
          rowSpan: getGroupedCellRowSpan(index ?? 0, item, dataItems, [
            "cumulatedGroupId",
          ]),
        }),
        render: (item: FatherComparisonItemData) => (
          <ValueWithCurrency
            value={item.deltaDirectCost}
            currencyCode={item.currencyCode}
            showSymbolIfNull={"-"}
          />
        ),
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.dm")}
            number={2}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
            compareModelWith={1}
          />
        ),
        key: "dm_column",
        align: "center",
        width: 70,
        onCell: (
          item: FatherComparisonItemData,
          index: number | undefined
        ) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
          rowSpan: getGroupedCellRowSpan(index ?? 0, item, dataItems, [
            "cumulatedGroupId",
          ]),
        }),
        render: (item: FatherComparisonItemData) => (
          <ValueWithCurrency
            value={item.deltaDm}
            currencyCode={item.currencyCode}
            showSymbolIfNull={"-"}
          />
        ),
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.dl")}
            number={2}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
            compareModelWith={1}
          />
        ),
        key: "dl_column",
        align: "center",
        width: 70,
        onCell: (
          item: FatherComparisonItemData,
          index: number | undefined
        ) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
          rowSpan: getGroupedCellRowSpan(index ?? 0, item, dataItems, [
            "cumulatedGroupId",
          ]),
        }),
        render: (item: FatherComparisonItemData) => (
          <ValueWithCurrency
            value={item.deltaDl}
            currencyCode={item.currencyCode}
            showSymbolIfNull={"-"}
          />
        ),
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.sc")}
            number={2}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
            compareModelWith={1}
          />
        ),
        key: "sc_column",
        align: "center",
        width: 70,
        onCell: (
          item: FatherComparisonItemData,
          index: number | undefined
        ) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
          rowSpan: getGroupedCellRowSpan(index ?? 0, item, dataItems, [
            "cumulatedGroupId",
          ]),
        }),
        render: (item: FatherComparisonItemData) => (
          <ValueWithCurrency
            value={item.deltaSc}
            currencyCode={item.currencyCode}
            showSymbolIfNull={"-"}
          />
        ),
      },
    ];

    return columns;
  }, [dataItems, t]);

  return {
    getTableColumns: getTableColumns,
  };
}
