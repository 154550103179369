import { ColumnsType } from "antd/es/table";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { formatNumber } from "src/core/utils/format";
import { getGroupedCellRowSpan } from "src/core/utils/table";
import { ValueWithCurrency } from "src/modules/ValueWithCurrency/ValueWithCurrency";
import { LensesDataItem } from "src/types/costExplosion";

export function useLensesDataItemColumns(dataItems: LensesDataItem[]) {
  const { t } = useTranslation("costExplosionComparison");

  const getTableColumns = useCallback(() => {
    const columns: ColumnsType<LensesDataItem> = [
      {
        title: t("tableColumn.lensMaterial"),
        key: "lensMaterial_column",
        align: "center",
        width: 100,
        onCell: (record: LensesDataItem, index: number | undefined) => ({
          rowSpan: getGroupedCellRowSpan(index ?? 0, record, dataItems, [
            "costTypeMapping",
            "lensMaterial",
          ]),
        }),
        render: (item: LensesDataItem) =>
          (item.rowType ?? null) !== null
            ? item.costTypeMapping
            : item.lensMaterial,
      },
      {
        title: t("tableColumn.lensType"),
        key: "lensType_column",
        align: "center",
        width: 100,
        render: (item: LensesDataItem) => item.lensType,
      },
      {
        title: t("tableColumn.photochromic"),
        key: "photochromic_column",
        align: "center",
        width: 110,
        render: (item: LensesDataItem) => item.photochromic,
      },
      {
        title: t("tableColumn.lensType"),
        key: "lensType_column",
        align: "center",
        width: 100,
        render: (item: LensesDataItem) => item.lensType,
      },
      {
        title: t("tableColumn.skuQuantity"),
        key: "skuQuantity_column",
        align: "center",
        width: 80,
        render: (item: LensesDataItem) => formatNumber(item.skuCount, 0),
      },
      {
        title: t("tableColumn.fullCost"),
        key: "fullCost_column",
        align: "center",
        width: 80,
        render: (item: LensesDataItem) => (
          <ValueWithCurrency
            value={item.fullCost}
            currencyCode={item.currencyCode}
          />
        ),
      },
      {
        title: t("tableColumn.directCost"),
        key: "directCost_column",
        align: "center",
        width: 80,
        render: (item: LensesDataItem) => (
          <ValueWithCurrency
            value={item.directCost}
            currencyCode={item.currencyCode}
          />
        ),
      },
      {
        title: t("tableColumn.variableCost"),
        key: "variableCost_column",
        align: "center",
        width: 80,
        render: (item: LensesDataItem) => (
          <ValueWithCurrency
            value={item.variableCost}
            currencyCode={item.currencyCode}
          />
        ),
      },
      {
        title: t("tableColumn.dm"),
        key: "dm_column",
        align: "center",
        render: (item: LensesDataItem) => (
          <ValueWithCurrency value={item.dm} currencyCode={item.currencyCode} />
        ),
      },
      {
        title: t("tableColumn.dl"),
        key: "dl_column",
        align: "center",
        render: (item: LensesDataItem) => (
          <ValueWithCurrency value={item.dl} currencyCode={item.currencyCode} />
        ),
      },
      {
        title: t("tableColumn.sc"),
        key: "sc_column",
        align: "center",
        render: (item: LensesDataItem) => (
          <ValueWithCurrency value={item.sc} currencyCode={item.currencyCode} />
        ),
      },
      {
        title: t("tableColumn.fov"),
        key: "fov_column",
        align: "center",
        width: 70,
        render: (item: LensesDataItem) => (
          <ValueWithCurrency
            value={item.fov}
            currencyCode={item.currencyCode}
          />
        ),
      },
      {
        title: t("tableColumn.fof1"),
        key: "fof1_column",
        align: "center",
        width: 70,
        render: (item: LensesDataItem) => (
          <ValueWithCurrency
            value={item.fof1}
            currencyCode={item.currencyCode}
          />
        ),
      },
      {
        title: t("tableColumn.fof2"),
        key: "fof2_column",
        align: "center",
        width: 70,
        render: (item: LensesDataItem) => (
          <ValueWithCurrency
            value={item.fof2}
            currencyCode={item.currencyCode}
          />
        ),
      },
      {
        title: t("tableColumn.mo"),
        key: "mo_column",
        align: "center",
        render: (item: LensesDataItem) => (
          <ValueWithCurrency value={item.mo} currencyCode={item.currencyCode} />
        ),
      },
      {
        title: t("tableColumn.dc"),
        key: "dc_column",
        align: "center",
        render: (item: LensesDataItem) => (
          <ValueWithCurrency value={item.dc} currencyCode={item.currencyCode} />
        ),
      },
      {
        title: t("tableColumn.pureTime"),
        key: "pureTime_column",
        align: "center",
        width: 80,
        render: (item: LensesDataItem) => formatNumber(item.pureTime),
      },
    ];

    return columns;
  }, [dataItems, t]);

  return {
    getTableColumns: getTableColumns,
  };
}
