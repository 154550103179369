import { TabsProps } from "antd";
import { useTranslation } from "react-i18next";
import { ThresholdsManagingFeature } from "src/modules/DataManagingManager/components/ThresholdsManagingFeature/ThresholdsManagingFeature";

export type DataManagingTabKey = "thresholds-managing-tab";

export function useDataManagingTabs() {
  const { t } = useTranslation();

  const tabs: TabsProps["items"] = [
    {
      key: "thresholds-managing-tab",
      label: t("tab.thresholds"),
      children: <ThresholdsManagingFeature />,
    },
  ];

  return tabs;
}
