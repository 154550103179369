import { API_BASE_URL } from "src/constants";
import { post } from "src/core/utils/api";
import { CostByFatherDataItem } from "src/types/costExplosion";

const createCostByFatherService = () => {
  const fetchFatherCodeDataItems = async (
    accessToken: string,
    modelNumber: string,
    skus: string[]
  ): Promise<CostByFatherDataItem[]> => {
    return await post<CostByFatherDataItem[]>(
      accessToken,
      API_BASE_URL +
        `/v1/ce/fatherCost/data/fatherCode?modelNumber=${modelNumber}`,
      { values: skus }
    );
  };

  const fetchFatherDescriptionDataItems = async (
    accessToken: string,
    modelNumber: string,
    skus: string[]
  ): Promise<CostByFatherDataItem[]> => {
    return await post<CostByFatherDataItem[]>(
      accessToken,
      API_BASE_URL +
        `/v1/ce/fatherCost/data/fatherDescription?modelNumber=${modelNumber}`,
      { values: skus }
    );
  };

  return { fetchFatherCodeDataItems, fetchFatherDescriptionDataItems };
};
export const costByFatherService = createCostByFatherService();
