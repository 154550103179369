import { Col, Row, Skeleton } from "antd";
import { useRecoilValue } from "recoil";
import { useViewPortQuery } from "src/core/hooks/useViewPortQuery";
import { ModelInfo } from "src/modules/CostExplosionManager/components/SelectedModelInfo/components/ModelInfo";
import { ModelStats } from "src/modules/CostExplosionManager/components/SelectedModelInfo/components/ModelStats";
import { costExplosionAppState } from "src/recoil/costExplosionAtoms";

export const SelectedModelInfo = () => {
  const isMobile = useViewPortQuery() === "mobile";

  const costExplosionAppStateData = useRecoilValue(costExplosionAppState);

  return (
    <Skeleton loading={costExplosionAppStateData?.loadingModelStats} active>
      <Row
        style={{
          paddingBottom: 35,
          border: "2px solid gray",
          padding: 15,
          width: "100%",
          margin: 0,
        }}
        gutter={25}
      >
        <Col xs={24} sm={12}>
          <ModelInfo
            containerCss={{
              borderRight: isMobile ? undefined : "2px solid gray",
              paddingRight: 15,
              width: "100%",
            }}
          />
        </Col>
        <Col xs={24} sm={12}>
          <ModelStats />
        </Col>
      </Row>
    </Skeleton>
  );
};
