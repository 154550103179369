import { Select } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { StringArray } from "src/types/common";

type Props = {
  baseColumns: ColumnsType<unknown>;
  setTableColumns: (columnsList: ColumnsType<unknown>[]) => void;
  unselectedColumnKeys?: string[];
  allowClear?: boolean;
  disabledColumnKeys?: string[];
  mergeColumnsByKey?: boolean;
  columnTitleOverrides?: StringArray;
  hiddenColumnKeys?: string[];
};

export const TableColumnsSelector: React.FC<Props> = (props: Props) => {
  const {
    baseColumns,
    setTableColumns,
    unselectedColumnKeys,
    allowClear,
    disabledColumnKeys,
    mergeColumnsByKey,
    hiddenColumnKeys,
    columnTitleOverrides,
  } = props;

  const { t } = useTranslation();

  const [baseColumnsState, setBaseColumnsState] = useState<
    ColumnsType<unknown>[]
  >([]);
  const [defaultColumnsState, setDefaultColumnsState] = useState<
    ColumnsType<unknown>[]
  >([]);

  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const [defaultUnselectedColumns] = useState<string[]>(
    unselectedColumnKeys ?? []
  );
  const [defaultSelectedColumns, setDefaultSelectedColumns] = useState<
    string[]
  >([]);

  const [columnsModified, setColumnsModified] = useState(false);

  useEffect(() => {
    if (baseColumns.length > 0) {
      let cols: ColumnsType<unknown>[] = [];

      baseColumns.forEach((c: any, index) =>
        cols.push({ ...c, key: `${index}_`.concat(c.key) })
      );

      setBaseColumnsState(getColumnsOrder(cols));
    }
  }, [baseColumns]);

  useEffect(() => {
    if (baseColumnsState.length > 0) {
      let initialColumns: string[] = [];
      let defaultColumns: ColumnsType<unknown>[] = [];

      baseColumnsState.forEach((c: any, columnIndex) => {
        if (
          defaultUnselectedColumns.findIndex(
            (columnKey) => columnKey === getColumnKey(c)
          ) === -1
        ) {
          initialColumns.push(c.key);
          defaultColumns.push(c);
        }
      });

      setSelectedColumns(initialColumns);
      setDefaultSelectedColumns(initialColumns);
      setDefaultColumnsState(defaultColumns);
    }
  }, [baseColumnsState, defaultUnselectedColumns]);

  useEffect(() => {
    if (defaultColumnsState.length > 0) {
      setTableColumns(defaultColumnsState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultColumnsState]);

  const getColumnsOrder = (columnsToOrder: ColumnsType<unknown>[]) => {
    return columnsToOrder.sort((a: any, b: any) => {
      const aAsInt = parseInt(a.key.split("_", 2)[0]);
      const bAsInt = parseInt(b.key.split("_", 2)[0]);

      if (aAsInt < bAsInt) {
        return -1;
      }

      if (aAsInt > bAsInt) {
        return 1;
      }

      return 0;
    });
  };

  const getSelectOptions = () => {
    let result: any[] = [];

    if (mergeColumnsByKey) {
      baseColumnsState.forEach((baseColumn) => {
        if (
          result.findIndex((c: any) => {
            return getColumnKey(c) === getColumnKey(baseColumn);
          }) === -1
        ) {
          result.push(baseColumn as ColumnsType<unknown>);
        }
      });
    } else {
      result = baseColumnsState;
    }

    if (hiddenColumnKeys && hiddenColumnKeys.length > 0) {
      hiddenColumnKeys.forEach((hiddenColKey: string) => {
        result = result.filter((col) => getColumnKey(col) !== hiddenColKey);
      });
    }

    if (disabledColumnKeys && disabledColumnKeys.length > 0) {
      result.forEach((c: any, index) => {
        if (
          disabledColumnKeys.findIndex((key) => `${index}_${key}` === c.key) !==
          -1
        ) {
          c.disabled = true;
        }
      });
    }

    if (defaultUnselectedColumns.length > 0) {
      result = [
        ...[
          {
            key: "action-reset",
            title: t("label.reset"),
            style: {
              color: columnsModified ? "blue" : "lightGray",
            },
            disabled: !columnsModified,
          },
        ],
        ...result,
      ];
    }

    result = [
      ...[
        {
          key: "action-select-all",
          title: t("label.selectAll"),
          style: {
            color:
              selectedColumns.length !== baseColumnsState.length
                ? "blue"
                : "lightGray",
          },
          disabled: selectedColumns.length === baseColumnsState.length,
        },
      ],
      ...result,
    ];

    if (columnTitleOverrides) {
      for (var key in columnTitleOverrides) {
        const colKey = key;

        const columnToOverrideIndex = result.findIndex(
          (col) => getColumnKey(col) === colKey
        );

        if (columnToOverrideIndex > -1) {
          result[columnToOverrideIndex] = {
            ...result[columnToOverrideIndex],
            title: columnTitleOverrides[key],
          };
        }
      }
    }

    return result;
  };

  const getColumnKey = (column: any) => {
    if (column.key) {
      const split = (column.key as string).split("_");

      if (split) {
        const key = split.slice(1).join("_");

        return key;
      }
    }
  };

  return (
    <div style={{ height: 35 }}>
      <Select
        mode="multiple"
        value={selectedColumns}
        allowClear={allowClear ?? false}
        style={{ width: 140, position: "absolute", right: 15 }}
        placeholder={t("label.columns")}
        maxTagPlaceholder={t("label.columns")}
        maxTagCount={0}
        className="table-column-selector"
        showSearch={false}
        defaultValue={[]}
        onChange={(values: string[], selectionColumns) => {
          if (values[values.length - 1] === "action-reset") {
            setTableColumns(defaultColumnsState);
            setSelectedColumns(defaultSelectedColumns);
            setColumnsModified(false);

            return;
          }

          if (values[values.length - 1] === "action-select-all") {
            let allColumns: string[] = [];

            baseColumnsState.forEach((c: any) => {
              allColumns.push(c.key);
            });

            setSelectedColumns(allColumns);
            setTableColumns(baseColumnsState);
            setColumnsModified((unselectedColumnKeys?.length ?? 0) > 0);

            return;
          }

          setSelectedColumns(values);

          let curentColumns = selectionColumns as ColumnsType<unknown>[];

          if (hiddenColumnKeys && hiddenColumnKeys.length > 0) {
            curentColumns = [
              ...curentColumns,
              ...baseColumnsState.filter((baseColumn) => {
                return (
                  hiddenColumnKeys.findIndex(
                    (hiddenColumnKey) =>
                      hiddenColumnKey === getColumnKey(baseColumn)
                  ) > -1
                );
              }),
            ];
          }

          if (mergeColumnsByKey) {
            let curentColumnsFinal: any[] = [];

            curentColumns.forEach((c) => {
              curentColumnsFinal = [
                ...curentColumnsFinal,
                ...baseColumnsState.filter((baseColumn) => {
                  return getColumnKey(baseColumn) === getColumnKey(c);
                }),
              ];
            });

            setTableColumns(getColumnsOrder(curentColumnsFinal));
          } else {
            setTableColumns(getColumnsOrder(curentColumns));
          }

          setColumnsModified(true);
        }}
        fieldNames={{ value: "key", label: "title" }}
        options={getSelectOptions()}
      />
    </div>
  );
};
