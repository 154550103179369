import { ColumnsType } from "antd/es/table";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import StickyTableColumn from "src/core/components/StickyTableColumn";
import { TextWithLink } from "src/core/components/TextWithLink";
import { useThresholdData } from "src/core/hooks/useThresholdData";
import { formatNumber } from "src/core/utils/format";
import { getGroupedCellRowSpan } from "src/core/utils/table";
import { getThresholdClass } from "src/core/utils/threshold";
import { ValueWithCurrency } from "src/modules/ValueWithCurrency/ValueWithCurrency";
import { ValueWithThreshold } from "src/modules/ValueWithThreshold/ValueWithThreshold";
import { ThresholdData } from "src/types/common";
import { RoutingsDataItem } from "src/types/costExplosion";

export function useRoutingsDataItemColumns(
  dataItems: RoutingsDataItem[],
  thresholdData: ThresholdData[] = []
) {
  const { t } = useTranslation("costExplosionComparison");

  const { dlThresholdValue, pureTimeThresholdValue } = useThresholdData(
    thresholdData,
    dataItems[0]?.currencyCode,
    "CE"
  );

  const getTableColumns = useCallback(() => {
    const columns: ColumnsType<RoutingsDataItem> = [
      {
        title: t("tableColumn.father"),
        key: "father_column",
        align: "center",
        onCell: (record: RoutingsDataItem, index: number | undefined) => ({
          rowSpan: getGroupedCellRowSpan(index ?? 0, record, dataItems, [
            "costTypeMapping",
            "father",
          ]),
        }),
        render: (item: RoutingsDataItem) => {
          return (
            <StickyTableColumn>
              {(item.rowType ?? null) !== null
                ? item.costTypeMapping
                : item.father}
            </StickyTableColumn>
          );
        },
      },
      {
        title: t("tableColumn.fatherCode"),
        key: "fatherCode_column",
        align: "center",
        width: 120,
        onCell: (record: RoutingsDataItem, index: number | undefined) => ({
          rowSpan: getGroupedCellRowSpan(index ?? 0, record, dataItems, [
            "costTypeMapping",
            "father",
            "fatherCode",
          ]),
        }),
        render: (item: RoutingsDataItem) => (
          <StickyTableColumn>
            <TextWithLink
              label={item.fatherCode}
              href={item.fatherCode3dSplm21xUrl}
            />
          </StickyTableColumn>
        ),
      },
      {
        title: t("tableColumn.component"),
        key: "component_column",
        width: 100,
        align: "center",
        onCell: (record: RoutingsDataItem, index: number | undefined) => ({
          rowSpan: getGroupedCellRowSpan(index ?? 0, record, dataItems, [
            "costTypeMapping",
            "father",
            "fatherCode",
            "component",
          ]),
        }),
        render: (item: RoutingsDataItem) => (
          <StickyTableColumn>{item.component}</StickyTableColumn>
        ),
      },
      {
        title: t("tableColumn.componentCode"),
        key: "componentCode_column",
        align: "center",
        width: 120,
        onCell: (record: RoutingsDataItem, index: number | undefined) => ({
          rowSpan: getGroupedCellRowSpan(index ?? 0, record, dataItems, [
            "costTypeMapping",
            "father",
            "fatherCode",
            "component",
            "componentCode",
          ]),
        }),
        render: (item: RoutingsDataItem) => (
          <StickyTableColumn>
            <TextWithLink
              label={item.componentCode}
              href={item.componentCode3dSplm21xUrl}
            />
          </StickyTableColumn>
        ),
      },
      {
        title: t("tableColumn.operationDescription"),
        key: "operationDescription_column",
        align: "center",
        render: (item: RoutingsDataItem) => item.operationCodeDescription,
      },
      {
        title: t("tableColumn.dl"),
        key: "dl_column",
        align: "center",
        width: 70,
        render: (item: RoutingsDataItem) => (
          <ValueWithCurrency
            value={item.dl}
            currencyCode={item.currencyCode}
            className={getThresholdClass(
              item.dl,
              dlThresholdValue,
              undefined,
              item.rowType !== undefined
            )}
          />
        ),
      },
      {
        title: t("tableColumn.fov"),
        key: "fov_column",
        align: "center",
        width: 65,
        render: (item: RoutingsDataItem) => (
          <ValueWithCurrency
            value={item.fov}
            currencyCode={item.currencyCode}
          />
        ),
      },
      {
        title: t("tableColumn.fof1"),
        key: "fof1_column",
        align: "center",
        width: 65,
        render: (item: RoutingsDataItem) => (
          <ValueWithCurrency
            value={item.fof1}
            currencyCode={item.currencyCode}
          />
        ),
      },
      {
        title: t("tableColumn.pureTime"),
        key: "pureTime_column",
        align: "center",
        width: 70,
        render: (item: RoutingsDataItem) => (
          <ValueWithThreshold
            value={item.pureTime}
            thresholdValue={pureTimeThresholdValue}
            disabled={item.rowType !== undefined}
          >
            {formatNumber(item.pureTime)}
          </ValueWithThreshold>
        ),
      },
      {
        title: t("tableColumn.efficiency"),
        key: "efficiency_column",
        align: "center",
        width: 80,
        render: (item: RoutingsDataItem) =>
          `${formatNumber(item?.efficiency)}%`,
      },
      {
        title: t("tableColumn.hourlyRate"),
        key: "hourlyRate_column",
        align: "center",
        width: 65,
        render: (item: RoutingsDataItem) => (
          <ValueWithCurrency
            value={item.hourlyRate}
            currencyCode={item.currencyCode}
          />
        ),
      },
      {
        title: t("tableColumn.costCenter"),
        key: "costCenter_column",
        align: "center",
        width: 160,
        render: (item: RoutingsDataItem) => item.costCenter,
      },
      {
        title: t("tableColumn.prodSched"),
        key: "prodSched_column",
        align: "center",
        width: 80,
        render: (item: RoutingsDataItem) => item.prodSchedule,
      },
    ];

    return columns;
  }, [dataItems, dlThresholdValue, pureTimeThresholdValue, t]);

  return {
    getTableColumns: getTableColumns,
  };
}
