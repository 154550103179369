import { Space } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useAuth } from "src/core/hooks/useAuth";
import { bomComparisonService } from "src/core/services/costComparisonServices/bomComparisonService";
import { ComparisonDeltaDirectionEnum } from "src/enums";
import { HoverTotalsContent } from "src/modules/ContentWithHoverTotals/components/HoverTotalsContent";
import { useBomComparisonItemData } from "src/modules/CostComparisonManager/tabs/BomTab/components/BomComparisonTable/hooks/useBomComparisonItemData";
import { useBomComparisonItemDataColumns } from "src/modules/CostComparisonManager/tabs/BomTab/components/BomComparisonTable/hooks/useBomComparisonItemDataColumns";
import { extractSkusList } from "src/modules/ModelSkuSelection/utils/utils";
import { TableColumnsSelector } from "src/modules/TableColumnsSelector/TableColumnsSelector";
import { appParamsState } from "src/recoil/atoms";
import {
  costComparisonAppState,
  costComparisonModel1GlobalState,
  costComparisonModel2GlobalState,
} from "src/recoil/costExplosionAtoms";
import { BomComparisonItemData } from "src/types/costComparison";

export const BomComparisonTable: React.FC = () => {
  const [tableLoading, setTableLoading] = useState(false);

  const { accessToken, success, authLoaded } = useAuth();

  const [dataItems, setDataItems] = useState<BomComparisonItemData[]>([]);

  const appParams = useRecoilValue(appParamsState);

  const costComparisonAppStateData = useRecoilValue(costComparisonAppState);

  const model1GlobalState = useRecoilValue(costComparisonModel1GlobalState);
  const model2GlobalState = useRecoilValue(costComparisonModel2GlobalState);

  const { processingData, processedData } = useBomComparisonItemData(dataItems);

  const { getTableColumns } = useBomComparisonItemDataColumns(
    processedData,
    "bomComparisonHoverTotals"
  );

  const [tableColumns, setTableColumns] = useState<
    ColumnsType<BomComparisonItemData>
  >([]);
  const [filteredTableColumns, setFilteredTableColumns] = useState<
    ColumnsType<BomComparisonItemData>
  >([]);

  useEffect(() => {
    (async () => {
      setTableColumns(getTableColumns());
      setFilteredTableColumns(getTableColumns());
    })();
  }, [getTableColumns]);

  useEffect(() => {
    (async () => {
      if (authLoaded && success) {
        if (!costComparisonAppStateData.selectingModelSkus) {
          let skusListModel1: string[] = extractSkusList(
            model1GlobalState.selectedModelSkus
          );
          let skusListModel2: string[] = extractSkusList(
            model2GlobalState.selectedModelSkus
          );

          if (skusListModel1.length > 0 && skusListModel2.length > 0) {
            setTableLoading(true);
            setDataItems([]);

            const response =
              await bomComparisonService.fetchBomComparisonDataItems(
                accessToken,
                {
                  firstModelNumber: model1GlobalState.modelNumber,
                  firstModelSku: { values: skusListModel1 },
                  secondModelNumber: model2GlobalState.modelNumber,
                  secondModelSku: { values: skusListModel2 },
                  deltaDirection: ComparisonDeltaDirectionEnum.OneToTwo,
                }
              );

            setDataItems(response);
          }

          setTableLoading(false);
        }
      }
    })();
  }, [
    accessToken,
    authLoaded,
    costComparisonAppStateData.selectingModelSkus,
    model1GlobalState.modelNumber,
    model1GlobalState.selectedModelSkus,
    model2GlobalState.modelNumber,
    model2GlobalState.selectedModelSkus,
    success,
  ]);

  return (
    <Space direction="vertical" size={20} style={{ width: "100%" }}>
      <TableColumnsSelector
        baseColumns={tableColumns as []}
        mergeColumnsByKey={true}
        setTableColumns={(columnsList) => {
          setFilteredTableColumns(
            columnsList as ColumnsType<BomComparisonItemData>
          );
        }}
        unselectedColumnKeys={[
          "standardUnitCost_column",
          "pureQuantity_column",
          "fopDescription_column",
        ]}
        hiddenColumnKeys={[
          "separator1_column",
          "separator2_column",
          "separator3_column",
        ]}
      />
      <Table
        columns={filteredTableColumns}
        className="table-with-relative-header-cells"
        loading={tableLoading || processingData}
        dataSource={processedData}
        pagination={false}
        rowKey={({ rowKey }) => `${rowKey}`}
        sticky={true}
        scroll={{ x: "max-content", y: 480 }}
        style={{ width: appParams.contentWidth - 60 }}
        rowClassName={(row) =>
          (row.rowType ?? null) !== null ? `row-type-class-${row.rowType}` : ""
        }
        size={"small"}
        bordered
        footer={() => {
          return <HoverTotalsContent contentKey="bomComparisonHoverTotals" />;
        }}
      />
    </Space>
  );
};
