import { API_BASE_URL } from "src/constants";
import { post } from "src/core/utils/api";
import { LensesDataItem, LensesDetailDataItem } from "src/types/costExplosion";

const createLensesService = () => {
  const fetchLensesDataItems = async (
    accessToken: string,
    modelNumber: string,
    skus: string[]
  ): Promise<LensesDataItem[]> => {
    return await post<LensesDataItem[]>(
      accessToken,
      API_BASE_URL + `/v1/ce/lens/data/recap?modelNumber=${modelNumber}`,
      { values: skus }
    );
  };

  const fetchLensesDetailDataItems = async (
    accessToken: string,
    modelNumber: string,
    skus: string[]
  ): Promise<LensesDetailDataItem[]> => {
    return await post<LensesDetailDataItem[]>(
      accessToken,
      API_BASE_URL + `/v1/ce/lens/data/details?modelNumber=${modelNumber}`,
      { values: skus }
    );
  };

  return { fetchLensesDataItems, fetchLensesDetailDataItems };
};

export const lensesService = createLensesService();
