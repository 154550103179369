import { Space } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { TableFooter } from "src/core/components/TableFooter";
import { useAuth } from "src/core/hooks/useAuth";
import { aliasMasterDataService } from "src/core/services/priceConfiguratorServices/aliasMasterDataService";
import { frameAliasesService } from "src/core/services/priceConfiguratorServices/frameAliasesService";
import { DataPropertyType } from "src/enums";
import {
  FrameAliasesContext,
  FrameAliasesContextType,
} from "src/modules/PriceConfiguratorManager/components/DataSectionAliasHandler/context/FrameAliasesContext";
import { useBomDataTableColumns } from "src/modules/PriceConfiguratorManager/tables/BomDataTable/hooks/useBomDataTableColumns";
import { TableColumnsSelector } from "src/modules/TableColumnsSelector/TableColumnsSelector";
import { appParamsState } from "src/recoil/atoms";
import { BomDataItem, DataSectionData } from "src/types/priceConfigurator";

type Props = {
  skuValue: string;
  aliasId: number;
  priceConfiguratorId: number;
  dataSection: DataSectionData;
  loading: boolean;
  disabled?: boolean;
};

export const BomDataTable: React.FC<Props> = (props: Props) => {
  const {
    loading,
    aliasId,
    priceConfiguratorId,
    dataSection,
    skuValue,
    disabled,
  } = props;

  const [tableLoading, setTableLoading] = useState(false);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<BomDataItem[]>([]);

  const { t } = useTranslation();

  const { accessToken, success, authLoaded } = useAuth();

  const appParams = useRecoilValue(appParamsState);
  const [bomDataItems, setBomDataItems] = useState<BomDataItem[]>([]);

  const { getTableColumns } = useBomDataTableColumns(bomDataItems);

  const [tableColumns, setTableColumns] = useState<ColumnsType<BomDataItem>>(
    []
  );
  const [filteredTableColumns, setFilteredTableColumns] = useState<
    ColumnsType<BomDataItem>
  >([]);

  const { setBillOfMaterialLoading } = React.useContext(
    FrameAliasesContext
  ) as FrameAliasesContextType;

  useEffect(() => {
    (async () => {
      if (authLoaded && success) {
        setTableColumns(getTableColumns());
        setFilteredTableColumns(getTableColumns());

        setTableLoading(false);
      }
    })();
  }, [authLoaded, getTableColumns, success]);

  useEffect(() => {
    (async () => {
      if (authLoaded && success) {
        if (skuValue) {
          setBillOfMaterialLoading(true);
          setTableLoading(true);

          const response = await aliasMasterDataService.fetchBomDataItems(
            accessToken,
            skuValue,
            priceConfiguratorId,
            dataSection.id,
            aliasId
          );

          const preselectedItems: BomDataItem[] = response.filter(
            (x: BomDataItem) => x.isSelected
          );

          const preselectedItemsKeys: number[] = preselectedItems.map(
            (y: BomDataItem) => {
              return y.id;
            }
          );

          setSelectedRows(preselectedItems);
          setSelectedRowKeys(preselectedItemsKeys);
          setBomDataItems(response);

          setTableLoading(false);
          setBillOfMaterialLoading(false);
        } else {
          setBomDataItems([]);
        }
      }
    })();
  }, [
    accessToken,
    aliasId,
    authLoaded,
    dataSection.id,
    priceConfiguratorId,
    setBillOfMaterialLoading,
    skuValue,
    success,
  ]);

  const rowSelection = {
    onChange: (selectedRowKey: React.Key[], selectedRows: BomDataItem[]) => {
      setSelectedRowKeys(selectedRowKey);
      setSelectedRows(selectedRows);
    },
    onSelect: async (item: BomDataItem, selected: boolean) => {
      setTableLoading(true);

      await aliasMasterDataService.updateBomDataItemSelection(
        accessToken,
        skuValue,
        item.id,
        {
          aliasId: aliasId,
          priceConfiguratorId: priceConfiguratorId,
          priceConfiguratorSectionId: dataSection.id,
        },
        selected
      );

      setTableLoading(false);
    },
    onSelectAll: async (selected: boolean) => {
      setTableLoading(true);

      await aliasMasterDataService.updateBomDataItemsSelection(
        accessToken,
        skuValue,
        {
          aliasId: aliasId,
          priceConfiguratorId: priceConfiguratorId,
          priceConfiguratorSectionId: dataSection.id,
        },
        selected
      );

      setTableLoading(false);
    },
    onSelectMultiple: async (
      selected: boolean,
      selectedRows: BomDataItem[],
      changeRows: BomDataItem[]
    ) => {
      setTableLoading(true);

      await frameAliasesService.updateBomDataItemsMultiSelection(
        accessToken,
        priceConfiguratorId,
        aliasId,
        dataSection.id,
        skuValue,
        changeRows,
        selected
      );

      setTableLoading(false);
    },
  };

  return (
    <Space direction="vertical">
      <TableColumnsSelector
        baseColumns={tableColumns as []}
        setTableColumns={(columnsList) => {
          setFilteredTableColumns(columnsList as ColumnsType<BomDataItem>);
        }}
        unselectedColumnKeys={[]}
      />
      <Table
        key="bom-data-table"
        className="no-selection-background-table small-font-table"
        sticky={true}
        bordered={true}
        columns={[...filteredTableColumns, ...[Table.SELECTION_COLUMN]]}
        loading={loading || tableLoading}
        dataSource={bomDataItems}
        pagination={false}
        rowKey={({ id }) => id}
        scroll={{ x: "max-content", y: 640 }}
        style={{ width: appParams.contentWidth - 60 }}
        rowSelection={{
          ...rowSelection,
          type: "checkbox",
          selectedRowKeys: selectedRowKeys,
          getCheckboxProps: () => {
            return { disabled: disabled };
          },
        }}
        size={"small"}
        footer={() => {
          return (
            <TableFooter
              dataItems={selectedRows}
              properties={[
                {
                  label: t("tableColumn.costDm"),
                  type: DataPropertyType.COST,
                  property: "costDm",
                },
                {
                  label: t("tableColumn.costSc"),
                  type: DataPropertyType.COST,
                  property: "costSc",
                },
                {
                  label: t("tableColumn.totalCost"),
                  type: DataPropertyType.COST,
                  property: "totalCost",
                },
              ]}
            />
          );
        }}
      />
    </Space>
  );
};
