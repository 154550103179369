import { ColumnsType } from "antd/es/table";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { TextWithLink } from "src/core/components/TextWithLink";
import { getGroupedCellRowSpan } from "src/core/utils/table";
import { getLastSplitPart } from "src/core/utils/text";
import { ValueWithCurrency } from "src/modules/ValueWithCurrency/ValueWithCurrency";
import { BomBySkuDataItem, SkuDataItem } from "src/types/costExplosion";

export function useBomBySkuTableColumns(dataItems: BomBySkuDataItem[]) {
  const { t } = useTranslation("costExplosionComparison");

  const getTableColumns = useCallback(() => {
    const getDynamicColumns = (): ColumnsType<BomBySkuDataItem> => {
      let result: ColumnsType<BomBySkuDataItem> = [];

      if (dataItems.length > 0) {
        const baseRecord = dataItems.find(
          (item: BomBySkuDataItem) =>
            item.rowType === undefined && item.skusDm && item.skusDm.length > 0
        );

        if (baseRecord && baseRecord.skusDm) {
          baseRecord.skusDm.forEach(
            (skuDm: SkuDataItem, skuDmIndex: number) => {
              if (skuDm.skuId) {
                result.push({
                  title: getLastSplitPart(skuDm.skuId, "_"),
                  key: "skuDm_column",
                  align: "center",
                  width: 125,
                  render: (item: BomBySkuDataItem) => {
                    if (item?.skusDm) {
                      return (
                        <ValueWithCurrency
                          value={item.skusDm[skuDmIndex]?.value}
                          currencyCode={dataItems[0].currencyCode}
                        />
                      );
                    }
                  },
                });
              }
            }
          );
        }
      }

      return result;
    };

    let initialColumns: ColumnsType<BomBySkuDataItem> = [
      {
        title: t("tableColumn.father"),
        key: "father_column",
        align: "center",
        width: 140,
        onCell: (record: BomBySkuDataItem, index: number | undefined) => ({
          rowSpan: getGroupedCellRowSpan(index ?? 0, record, dataItems, [
            "costTypeMapping",
            "father",
          ]),
        }),
        render: (item: BomBySkuDataItem) =>
          (item.rowType ?? null) !== null ? item.costTypeMapping : item.father,
      },
      {
        title: t("tableColumn.fatherPrevious"),
        key: "fatherPrevious_column",
        align: "center",
        width: 120,
        onCell: (record: BomBySkuDataItem, index: number | undefined) => ({
          rowSpan: getGroupedCellRowSpan(index ?? 0, record, dataItems, [
            "costTypeMapping",
            "father",
            "fatherPrevious",
          ]),
        }),
        render: (item: BomBySkuDataItem) => item.fatherPrevious,
      },
      {
        title: t("tableColumn.component"),
        key: "component_column",
        width: 100,
        align: "center",
        onCell: (record: BomBySkuDataItem, index: number | undefined) => ({
          rowSpan: getGroupedCellRowSpan(index ?? 0, record, dataItems, [
            "costTypeMapping",
            "father",
            "fatherPrevious",
            "component",
          ]),
        }),
        render: (item: BomBySkuDataItem) => item.component,
      },
      {
        title: t("tableColumn.componentCode"),
        key: "componentCode_column",
        align: "center",
        width: 120,
        onCell: (record: BomBySkuDataItem, index: number | undefined) => ({
          rowSpan: getGroupedCellRowSpan(index ?? 0, record, dataItems, [
            "costTypeMapping",
            "father",
            "fatherPrevious",
            "component",
            "componentCode",
          ]),
        }),
        render: (item: BomBySkuDataItem) => (
          <TextWithLink
            label={item.componentCode}
            href={item.componentCode3dSplm21xUrl}
          />
        ),
      },
      {
        title: t("tableColumn.dm"),
        key: "dl_column",
        align: "center",
        width: 70,
        render: (item: BomBySkuDataItem) => (
          <ValueWithCurrency value={item.dm} currencyCode={item.currencyCode} />
        ),
      },
    ];

    const columns: ColumnsType<BomBySkuDataItem> = [
      ...initialColumns,
      ...getDynamicColumns(),
      ...([
        {
          title: t("tableColumn.fopDescription"),
          key: "fopDescription_column",
          align: "center",
          width: 120,
          render: (item: BomBySkuDataItem) => item.fopDescription,
        },
      ] as ColumnsType<BomBySkuDataItem>),
    ];

    return columns;
  }, [dataItems, t]);

  return {
    getTableColumns: getTableColumns,
  };
}
