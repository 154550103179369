import Table, { ColumnsType } from "antd/es/table";
import { useTranslation } from "react-i18next";
import { ValueWithCurrency } from "src/modules/ValueWithCurrency/ValueWithCurrency";
import { ModelOptionsGroup } from "src/types/costExplosion";

export function useModelSelectionItemColumns() {
  const { t } = useTranslation("costExplosionComparison");

  const columns: ColumnsType<ModelOptionsGroup> = [
    {
      title: t("tableColumn.model"),
      key: "model_number",
      align: "center",
      render: (item: ModelOptionsGroup) => item.modelNumber,
    },
    {
      title: t("tableColumn.valType"),
      key: "val_type",
      align: "center",
      width: 240,
      render: (item: ModelOptionsGroup) => item.valType,
    },
    {
      title: t("tableColumn.costLevel"),
      key: "cost_level",
      align: "center",
      render: (item: ModelOptionsGroup) =>
        t(`enum.costLevel_${item.costLevel}`),
    },
    {
      title: t("tableColumn.madeIn"),
      key: "made_in",
      align: "center",
      render: (item: ModelOptionsGroup) => item.madeIn,
    },
    {
      title: t("tableColumn.collection"),
      key: "collection",
      align: "center",
      render: (item: ModelOptionsGroup) => item.collection,
    },
    {
      title: t("tableColumn.material"),
      key: "material",
      align: "center",
      render: (item: ModelOptionsGroup) => item.material,
    },
    {
      title: t("tableColumn.modelRelease"),
      key: "model_release",
      align: "center",
      render: (item: ModelOptionsGroup) => item.release,
    },
    {
      title: t("tableColumn.fullCost"),
      key: "full_cost",
      align: "center",
      render: (item: ModelOptionsGroup) => (
        <ValueWithCurrency
          value={item.fullCost}
          currencyCode={item.currencyCode}
        />
      ),
    },
    {
      title: t("tableColumn.frameFc"),
      key: "frame_fc",
      align: "center",
      render: (item: ModelOptionsGroup) => (
        <ValueWithCurrency
          value={item.frameFc}
          currencyCode={item.currencyCode}
        />
      ),
    },
    {
      title: t("tableColumn.lensFc"),
      key: "lens_fc",
      align: "center",
      render: (item: ModelOptionsGroup) => (
        <ValueWithCurrency
          value={item.lensFc}
          currencyCode={item.currencyCode}
        />
      ),
    },
    {
      title: t("tableColumn.pkgFc"),
      key: "pkg_fc",
      align: "center",
      render: (item: ModelOptionsGroup) => (
        <ValueWithCurrency
          value={item.pkgFc}
          currencyCode={item.currencyCode}
        />
      ),
    },
    {
      title: t("tableColumn.directCost"),
      key: "direct_cost",
      align: "center",
      render: (item: ModelOptionsGroup) => (
        <ValueWithCurrency
          value={item.directCost}
          currencyCode={item.currencyCode}
        />
      ),
    },
    Table.SELECTION_COLUMN,
  ];

  return {
    columns: columns,
  };
}
