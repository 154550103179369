import { Collapse, CollapseProps, Space } from "antd";
import Title from "antd/es/typography/Title";
import { useTranslation } from "react-i18next";
import { SelectedModelInfo } from "src/modules/CostExplosionManager/components/SelectedModelInfo/SelectedModelInfo";
import { FatherCodeTable } from "src/modules/CostExplosionManager/tabs/CostByFatherTab/components/FatherCodeTable/FatherCodeTable";
import { FatherDescriptionTable } from "src/modules/CostExplosionManager/tabs/CostByFatherTab/components/FatherDescriptionTable/FatherDescriptionTable";

export const CostByFatherTab = () => {
  const { t } = useTranslation("costExplosionComparison");

  const items: CollapseProps["items"] = [
    {
      key: "father-description",
      label: <Title level={4}>{t("title.fatherDescription")}</Title>,
      children: <FatherDescriptionTable />,
    },
    {
      key: "father-code",
      label: <Title level={4}>{t("title.fatherCode")}</Title>,
      children: <FatherCodeTable />,
    },
  ];

  return (
    <Space direction="vertical" style={{ width: "100%" }} size={25}>
      <SelectedModelInfo />
      <Collapse
        defaultActiveKey={["father-description", "father-code"]}
        ghost
        items={items}
      />
    </Space>
  );
};
