import { convertStringToTitleCase } from "src/core/utils/text";

export const calculatePropertySum = (items: unknown[], property: string) => {
  const array = items as [];
  let totalSum = 0;

  array.forEach((element) => {
    totalSum += element[property] ?? 0;
  });

  return totalSum;
};

export const calculatePropertyAverage = (
  items: unknown[],
  property: string
): number => {
  const array = items as [];

  if (array.length > 0) {
    let totalSum = 0;

    array.forEach((element) => {
      totalSum += element[property] ?? 0;
    });

    return totalSum / array.length;
  }

  return 0;
};

export const joinUniqueProperties = (
  items: unknown[],
  property: string,
  joinSymbol: string,
  caseSensitive: boolean = false,
  convertToTitleCase: boolean = false
) => {
  const array = items as [];
  let elementsToJoin: string[] = [];

  array.forEach((el) => {
    if (
      elementsToJoin.findIndex((ex) =>
        caseSensitive
          ? ex === el[property]
          : (ex as string)?.toLowerCase() ===
            (el[property] as string)?.toLowerCase()
      ) === -1
    ) {
      elementsToJoin.push(
        convertToTitleCase
          ? convertStringToTitleCase(el[property] as string)
          : el[property]
      );
    }
  });

  return elementsToJoin.join(joinSymbol);
};

export const calculateWeightedAverage = (
  items: unknown[],
  prop1: string,
  prop2: string
) => {
  const array = items as [];

  let totalSum: number = 0;

  array.forEach((item) => {
    totalSum = totalSum + (item[prop1] ?? 0) * (item[prop2] ?? 0);
  });

  const totalWeight = calculatePropertySum(items, prop2);

  return totalWeight > 0 ? totalSum / totalWeight : 0;
};
