import { Skeleton } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DataSectionType } from "src/enums";
import { FrameAcetate } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/FrameAcetate/FrameAcetate";
import { Lenses } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/Lenses/Lenses";
import { MadeInOrigin } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/MadeInOrigin/MadeInOrigin";
import { ManualAdjustments } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/ManualAdjustments/ManualAdjustments";
import { Overhead } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/Overhead/Overhead";
import { Packaging } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/Packaging/Packaging";
import { Recap } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/Recap/Recap";
import { DataSectionData } from "src/types/priceConfigurator";

type Props = {
  dataSection: DataSectionData;
};

export const DataSectionManager: React.FC<Props> = (props: Props) => {
  const { dataSection } = props;

  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setLoading(false);
    })();
  }, []);

  const renderDataSection = (type: DataSectionType) => {
    switch (type) {
      case DataSectionType.FRAMEACETATEMIX: {
        return <FrameAcetate dataSection={dataSection} />;
      }
      case DataSectionType.LENSES: {
        return <Lenses dataSection={dataSection} />;
      }
      case DataSectionType.PACKAGING: {
        return <Packaging dataSection={dataSection} />;
      }
      case DataSectionType.MANUALADJUST: {
        return <ManualAdjustments dataSection={dataSection} />;
      }
      case DataSectionType.OVHCOSTS: {
        return <Overhead dataSection={dataSection} />;
      }
      case DataSectionType.MADEINORIGIN: {
        return <MadeInOrigin dataSection={dataSection} />;
      }
      case DataSectionType.PACARECAP: {
        return <Recap dataSection={dataSection} />;
      }
      default: {
        return t("message.notImplemented");
      }
    }
  };

  return (
    <Skeleton
      loading={loading}
      style={{ minHeight: 300, width: "100vh" }}
      active
    >
      {renderDataSection(dataSection.sectionType)}
    </Skeleton>
  );
};
