import { Space } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { useAuth } from "src/core/hooks/useAuth";
import { costBySkuService } from "src/core/services/costExplosionServices/costBySkuService";
import { useRoutingBySkuDataItemData } from "src/modules/CostExplosionManager/tabs/CostBySkuTab/components/RoutingBySkuTable/hooks/useRoutingBySkuDataItemData";
import { useRoutingBySkuTableColumns } from "src/modules/CostExplosionManager/tabs/CostBySkuTab/components/RoutingBySkuTable/hooks/useRoutingBySkuTableColumns";

import { extractSkusList } from "src/modules/ModelSkuSelection/utils/utils";
import { TableColumnsSelector } from "src/modules/TableColumnsSelector/TableColumnsSelector";
import {
  costExplosionAppState,
  costExplosionGlobalState,
} from "src/recoil/costExplosionAtoms";
import { tableWidthSelector } from "src/recoil/selectors";
import { RoutingBySkuDataItem } from "src/types/costExplosion";

export const RoutingBySkuTable = () => {
  const [tableLoading, setTableLoading] = useState(false);

  const { accessToken, success, authLoaded } = useAuth();

  const [dataItems, setDataItems] = useState<RoutingBySkuDataItem[]>([]);

  const tableWidth = useRecoilValue(tableWidthSelector);

  const costExplosionAppStateData = useRecoilValue(costExplosionAppState);

  const costExplosionState = useRecoilValue(costExplosionGlobalState);

  const { processed, processedData } = useRoutingBySkuDataItemData(dataItems);
  const { getTableColumns } = useRoutingBySkuTableColumns(processedData);

  const { t } = useTranslation("costExplosionComparison");

  const [tableColumns, setTableColumns] = useState<
    ColumnsType<RoutingBySkuDataItem>
  >([]);
  const [filteredTableColumns, setFilteredTableColumns] = useState<
    ColumnsType<RoutingBySkuDataItem>
  >([]);

  useEffect(() => {
    (async () => {
      setTableColumns(getTableColumns());
      setFilteredTableColumns(getTableColumns());
    })();
  }, [getTableColumns]);

  useEffect(() => {
    (async () => {
      if (authLoaded && success) {
        if (!costExplosionAppStateData.selectingModelSku) {
          if (
            costExplosionState.selectedModelSkus &&
            costExplosionState.selectedModelSkus?.length > 0
          ) {
            setTableLoading(true);

            let skusList: string[] = extractSkusList(
              costExplosionState.selectedModelSkus
            );

            if (skusList.length > 0) {
              const response =
                await costBySkuService.fetchRoutingBySkuDataItems(
                  accessToken,
                  costExplosionState.modelNumber ?? "",
                  skusList
                );

              setDataItems(response);
            }

            setTableLoading(false);
          }
        }
      }
    })();
  }, [
    accessToken,
    authLoaded,
    costExplosionAppStateData.selectingModelSku,
    costExplosionState.modelNumber,
    costExplosionState.selectedModelSkus,
    success,
  ]);

  return (
    <Space direction="vertical" size={20} style={{ width: "100%" }}>
      <TableColumnsSelector
        baseColumns={tableColumns as []}
        setTableColumns={(columnsList) => {
          setFilteredTableColumns(
            columnsList as ColumnsType<RoutingBySkuDataItem>
          );
        }}
        mergeColumnsByKey={true}
        columnTitleOverrides={{
          skuDl_column: t("label.dlBySku"),
          skuPureTime_column: t("label.pureTimeBySku"),
        }}
      />
      <Table
        columns={filteredTableColumns}
        loading={tableLoading || !processed}
        dataSource={processedData}
        pagination={false}
        rowKey={({ rowKey }) => `${rowKey}`}
        sticky={true}
        scroll={{
          x: "max-content",
          y: processedData.length > 0 ? 480 : undefined,
        }}
        style={{ width: tableWidth }}
        rowClassName={(row) =>
          (row.rowType ?? null) !== null ? `row-type-class-${row.rowType}` : ""
        }
        size={"small"}
        bordered
      />
    </Space>
  );
};
