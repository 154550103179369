import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Flex, Image, ImageProps, Skeleton } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import ImagePlaceholder from "src/assets/imgs/image_placeholder.svg";
import { useAuth } from "src/core/hooks/useAuth";
import { masterDataService } from "src/core/services/masterDataService";
import { isEmptyOrNullUndefined } from "src/core/utils/text";
import { ImageBase } from "src/modules/ModelImageViewer/components/ImageBase";
import { MODEL_3D_SPLM_21X_URL } from "src/modules/ModelImageViewer/constants/constants";
import {
  generateModelImageItems,
  generateModelImageP21Items,
} from "src/modules/ModelImageViewer/utils/utils";

export type ImageViewerImageData = ImageProps & {
  thumbnailSrc?: string;
};

type Props = {
  modelNumber?: string;
  valType?: string;
  width: number | string;
  containerHeight?: number | string;
  preview: boolean;
  imgWidthToLoad?: number;
  hideIfNotFound?: boolean;
  notFoundImageHeight?: number;
  notFoundImageWidth?: number;
  showArrows?: boolean;
};

export const ModelImageViewerP21: React.FC<Props> = (props: Props) => {
  const {
    modelNumber,
    width,
    preview,
    imgWidthToLoad,
    hideIfNotFound,
    containerHeight,
    notFoundImageHeight,
    valType,
    notFoundImageWidth,
    showArrows,
  } = props;

  const { accessToken, success, authLoaded } = useAuth();

  const [viewerModelNumber, setViewerModelNumber] = useState<string>();
  const [viewerValType, setViewerValType] = useState<string>();

  const [generating, setGenerating] = useState(false);
  const [imageUrl, setImageUrl] = useState<string | undefined>();
  const [imageItems, setImageItems] = useState<ImageViewerImageData[]>([]);

  const [errorState, setErrorState] = useState(false);
  const [useFallbackViewer, setUseFallbackViewer] = useState(false);

  const [currentIndex, setCurrentIndex] = useState(0);

  const isModelNumberReady = useCallback((): boolean => {
    return validateModelNumber(viewerModelNumber);
  }, [viewerModelNumber]);

  const validateModelNumber = (valueToCheck?: string): boolean => {
    return !!(valueToCheck && valueToCheck.length > 4) ?? false;
  };

  useEffect(() => {
    (async () => {
      if (authLoaded && success) {
        setUseFallbackViewer(false);

        if (isEmptyOrNullUndefined(modelNumber)) {
          setImageItems([]);
          setImageUrl(undefined);
        }

        setCurrentIndex(0);
        setErrorState(false);

        if (isEmptyOrNullUndefined(valType)) {
          if (modelNumber && validateModelNumber(modelNumber)) {
            setGenerating(true);

            var response =
              await masterDataService.fetchRandomValTypeByModelNumber(
                accessToken,
                modelNumber
              );

            setViewerValType(response.value);

            setGenerating(false);
          } else {
            setViewerValType(undefined);
          }
        } else {
          setViewerValType(valType);
        }

        setViewerModelNumber(modelNumber?.toUpperCase());
      }
    })();
  }, [accessToken, authLoaded, modelNumber, success, valType]);

  useEffect(() => {
    if (isModelNumberReady()) {
      setGenerating(true);

      const items = generateModelImageP21Items(
        viewerModelNumber,
        viewerValType,
        imgWidthToLoad
      );

      setImageItems(items);

      setGenerating(false);
    }
  }, [
    imgWidthToLoad,
    isModelNumberReady,
    viewerModelNumber,
    viewerValType,
    width,
  ]);

  useEffect(() => {
    if (isModelNumberReady() && useFallbackViewer) {
      setErrorState(false);

      const items = generateModelImageItems(
        viewerModelNumber,
        viewerValType,
        imgWidthToLoad
      );

      setImageItems(items);
    }
  }, [
    imgWidthToLoad,
    isModelNumberReady,
    viewerModelNumber,
    useFallbackViewer,
    viewerValType,
    width,
  ]);

  useEffect(() => {
    if (imageItems[currentIndex]) {
      setImageUrl(imageItems[currentIndex].thumbnailSrc);
    }
  }, [currentIndex, imageItems]);

  const checkIfshowArrows = (): boolean => {
    return (
      (showArrows &&
        !errorState &&
        !generating &&
        modelNumber !== undefined &&
        modelNumber.length > 4) ??
      false
    );
  };

  return (
    <Skeleton
      loading={generating}
      style={{
        height: notFoundImageHeight ?? width,
        width: notFoundImageWidth ?? width,
      }}
      active
    >
      <Image.PreviewGroup
        preview={{
          style: { background: "#ffffff80" },
          current: currentIndex,
          onChange: (current: number) => {
            setCurrentIndex(current);
          },
        }}
        items={imageItems}
        fallback={ImagePlaceholder}
      >
        <Flex align="center" justify="center">
          {checkIfshowArrows() && (
            <Button
              icon={<LeftOutlined />}
              disabled={currentIndex === 0}
              onClick={() => {
                setCurrentIndex((prevIndex) => prevIndex - 1);
              }}
            />
          )}
          <ImageBase
            imageUrl={imageUrl}
            width={width}
            containerHeight={containerHeight}
            generating={generating}
            preview={preview}
            hideIfNotFound={hideIfNotFound}
            notFoundImageHeight={notFoundImageHeight}
            notFoundImageWidth={notFoundImageWidth}
            onError={() => {
              setErrorState(true);
              setUseFallbackViewer(true);
            }}
            errorImageLink={
              isModelNumberReady()
                ? `${MODEL_3D_SPLM_21X_URL}${viewerModelNumber}`
                : undefined
            }
            errorImageLinkText={isModelNumberReady() ? "PLM" : undefined}
          />
          {checkIfshowArrows() && (
            <Button
              icon={<RightOutlined />}
              disabled={currentIndex === imageItems.length - 1}
              onClick={() => {
                setCurrentIndex((prevIndex) => prevIndex + 1);
              }}
            />
          )}
        </Flex>
      </Image.PreviewGroup>
    </Skeleton>
  );
};
