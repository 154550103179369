import { Collapse, CollapseProps, Space } from "antd";
import Title from "antd/es/typography/Title";
import { useTranslation } from "react-i18next";
import { SelectedModelInfo } from "src/modules/CostExplosionManager/components/SelectedModelInfo/SelectedModelInfo";
import { LensesDetailTable } from "src/modules/CostExplosionManager/tabs/LensesTab/components/LensesDetailTable/LensesDetailTable";
import { LensesRecapTable } from "src/modules/CostExplosionManager/tabs/LensesTab/components/LensesRecapTable/LensesRecapTable";

export const LensesTab = () => {
  const { t } = useTranslation("costExplosionComparison");

  const items: CollapseProps["items"] = [
    {
      key: "lenses-recap",
      label: <Title level={4}>{t("title.lensRecap")}</Title>,
      children: <LensesRecapTable />,
    },
    {
      key: "lenses-detail",
      label: <Title level={4}>{t("title.lensDetail")}</Title>,
      children: <LensesDetailTable />,
    },
  ];

  return (
    <Space direction="vertical" style={{ width: "100%" }} size={25}>
      <SelectedModelInfo />
      <Collapse
        defaultActiveKey={["lenses-recap", "lenses-detail"]}
        ghost
        items={items}
      />
    </Space>
  );
};
