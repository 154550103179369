import { Space, Typography } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import Title from "antd/es/typography/Title";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CircleWithColor } from "src/core/components/CircleWithColor";
import { textWithSuffix } from "src/core/utils/text";
import { MadeInOriginStatsTypeEnum } from "src/enums";
import { MadeInOriginStatsItem } from "src/types/priceConfigurator";

type Props = {
  dataItems: MadeInOriginStatsItem[];
  dataRefreshing?: boolean;
};

const { Text } = Typography;

export const HeaderTable = (props: Props) => {
  const { dataItems, dataRefreshing } = props;

  const { t } = useTranslation();

  useEffect(() => {
    (async () => {})();
  }, []);

  const columns: ColumnsType<MadeInOriginStatsItem> = [
    {
      key: "made_in_origin_type_column",
      width: "33%",
      render: (item: MadeInOriginStatsItem) => (
        <Text
          style={{
            textAlign: "left",
            fontWeight: 600,
          }}
        >
          {`${t(`label.madeInOriginStatsType_${item.type}`)} ${
            item.type === MadeInOriginStatsTypeEnum.MADEIN
              ? `(>${item.warningThresholdValue}%)`
              : item.type === MadeInOriginStatsTypeEnum.ORIGIN
              ? `(<${item.warningThresholdValue}%)`
              : ``
          }`}
        </Text>
      ),
    },
    {
      title: t("tableColumn.costKoInEuros"),
      key: "costKoInEuros_column",
      align: "center",
      width: "33%",
      render: (item: MadeInOriginStatsItem) => (
        <Space
          className="ignore-full-width-space"
          align="center"
          style={{
            textAlign: "center",
            alignContent: "center",
            justifyContent: "center",
            width: "100%",
            fontWeight: 600,
          }}
        >
          <CircleWithColor color={item.valueIndicator?.color} />
          <Text>{textWithSuffix(item.value, "%")}</Text>
        </Space>
      ),
    },
    {
      title: t("tableColumn.costKoInEurosNewEr"),
      key: "costKoInEurosNewEr_column",
      align: "center",
      width: "33%",
      render: (item: MadeInOriginStatsItem) => (
        <Space
          className="ignore-full-width-space"
          align="center"
          style={{
            textAlign: "center",
            alignContent: "center",
            justifyContent: "center",
            width: "100%",
            fontWeight: 600,
          }}
        >
          <CircleWithColor color={item.valueNewErIndicator?.color} />
          <Text>{textWithSuffix(item.valueNewEr, "%")}</Text>
        </Space>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Title style={{ marginBottom: 5, display: "block" }} level={5}>
        {t("label.madeInOriginHeaderTitle")}
      </Title>
      <Table
        columns={columns}
        loading={dataRefreshing}
        dataSource={dataItems}
        pagination={false}
        rowKey={(record) => `${record.type}`}
        scroll={{ x: "max-content" }}
        bordered={true}
        size={"small"}
        style={{ marginBottom: 30 }}
      />
    </React.Fragment>
  );
};
