import { ColumnsType } from "antd/es/table";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { formatNumber } from "src/core/utils/format";
import { getGroupedCellRowSpan } from "src/core/utils/table";
import { ValueWithCurrency } from "src/modules/ValueWithCurrency/ValueWithCurrency";
import { BomDataItem } from "src/types/priceConfigurator";

export function useBomDataTableColumns(bomDataItems: BomDataItem[]) {
  const { t } = useTranslation();

  const getTableColumns = useCallback(() => {
    const columns: ColumnsType<BomDataItem> = [
      {
        title: t("tableColumn.father"),
        key: "bom-father",
        align: "center",
        width: 80,
        render: (record: BomDataItem) => record.father,
        onCell: (record: BomDataItem, index: number | undefined) => ({
          rowSpan: getGroupedCellRowSpan(index ?? 0, record, bomDataItems, [
            "father",
          ]),
        }),
      },
      {
        title: t("tableColumn.fatherCode"),
        key: "bom-fatherCode",
        align: "center",
        width: 80,
        render: (record: BomDataItem) => record.fatherCode,
        onCell: (record: BomDataItem, index: number | undefined) => ({
          rowSpan: getGroupedCellRowSpan(index ?? 0, record, bomDataItems, [
            "father",
            "fatherCode",
          ]),
        }),
      },
      {
        title: t("tableColumn.component"),
        key: "bom-component",
        align: "center",
        width: 100,
        render: (_, record) => record.component,
        onCell: (record: BomDataItem, index: number | undefined) => ({
          rowSpan: getGroupedCellRowSpan(index ?? 0, record, bomDataItems, [
            "father",
            "fatherCode",
            "component",
          ]),
        }),
      },
      {
        title: t("tableColumn.componentCode"),
        key: "bom-component-code",
        align: "center",
        width: 80,
        render: (_, record) => record.componentCode,
      },
      {
        title: t("tableColumn.componentDescription"),
        key: "bom-component-description",
        align: "center",
        width: 150,
        render: (_, record) => record.componentDescription,
      },
      {
        title: t("tableColumn.fop"),
        key: "bom-fop",
        align: "center",
        width: 100,
        render: (_, record) => record.fop,
      },
      {
        title: t("tableColumn.um"),
        key: "bom-um",
        width: 80,
        align: "center",
        render: (_, record) => record.um,
      },
      {
        title: t("tableColumn.fatherComponentsValType"),
        key: "bom-fatherComponentsValType",
        width: 80,
        align: "center",
        render: (_, record) => record.fatherComponentsValType,
      },
      {
        title: t("tableColumn.pureQuantity"),
        key: "bom-pure-quantity",
        align: "center",
        width: 80,
        render: (_, record) => formatNumber(record.pureQuantity),
      },
      {
        title: t("tableColumn.standardUnitCost"),
        key: "bom-standart-unit-cost",
        width: 80,
        align: "center",
        render: (_, record) => (
          <ValueWithCurrency
            value={record.standardUnitCost}
            currencyCode={record.currencyCode}
          />
        ),
      },
      {
        title: t("tableColumn.costDm"),
        key: "bom-cost-dm",
        align: "center",
        width: 80,
        className: "gray-background-column",
        render: (_, record) => (
          <ValueWithCurrency
            value={record.costDm}
            currencyCode={record.currencyCode}
          />
        ),
      },
      {
        title: t("tableColumn.costSc"),
        key: "bom-cost-sc",
        align: "center",
        width: 80,
        render: (_, record) => (
          <ValueWithCurrency
            value={record.costSc}
            currencyCode={record.currencyCode}
          />
        ),
      },
      {
        title: t("tableColumn.totalCost"),
        key: "bom-total-cost",
        align: "center",
        width: 80,
        render: (_, record) => (
          <ValueWithCurrency
            value={record.totalCost}
            currencyCode={record.currencyCode}
          />
        ),
      },
    ];

    return columns;
  }, [bomDataItems, t]);

  return {
    getTableColumns: getTableColumns,
  };
}
