import { ColumnsType } from "antd/es/table";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { TextWithLink } from "src/core/components/TextWithLink";
import { formatNumber } from "src/core/utils/format";
import { getGroupedCellRowSpan } from "src/core/utils/table";
import { getLastSplitPart } from "src/core/utils/text";
import { ValueWithCurrency } from "src/modules/ValueWithCurrency/ValueWithCurrency";
import { RoutingBySkuDataItem, SkuDataItem } from "src/types/costExplosion";

export function useRoutingBySkuTableColumns(dataItems: RoutingBySkuDataItem[]) {
  const { t } = useTranslation("costExplosionComparison");

  const getTableColumns = useCallback(() => {
    const getDynamicColumns1 = (): ColumnsType<RoutingBySkuDataItem> => {
      let result: ColumnsType<RoutingBySkuDataItem> = [];

      if (dataItems.length > 0) {
        const baseRecord = dataItems.find(
          (item: RoutingBySkuDataItem) =>
            item.rowType === undefined && item.skusDl && item.skusDl.length > 0
        );

        if (baseRecord) {
          if (baseRecord.skusDl) {
            baseRecord.skusDl.forEach(
              (skuDataItem: SkuDataItem, index: number) => {
                if (skuDataItem.skuId) {
                  result.push({
                    title: getLastSplitPart(skuDataItem.skuId, "_"),
                    key: "skuDl_column",
                    align: "center",
                    width: 125,
                    render: (item: RoutingBySkuDataItem) => {
                      if (item?.skusDl) {
                        return (
                          <ValueWithCurrency
                            value={item.skusDl[index]?.value}
                            currencyCode={dataItems[0].currencyCode}
                          />
                        );
                      }
                    },
                  });
                }
              }
            );
          }
        }
      }

      return result;
    };

    const getDynamicColumns2 = (): ColumnsType<RoutingBySkuDataItem> => {
      let result: ColumnsType<RoutingBySkuDataItem> = [];

      if (dataItems.length > 0) {
        const baseRecord = dataItems.find(
          (item: RoutingBySkuDataItem) =>
            item.rowType === undefined &&
            item.skusPureTime &&
            item.skusPureTime.length > 0
        );

        if (baseRecord) {
          if (baseRecord.skusPureTime) {
            baseRecord.skusPureTime.forEach(
              (skuDataItem: SkuDataItem, index: number) => {
                if (skuDataItem.skuId) {
                  result.push({
                    title: getLastSplitPart(skuDataItem.skuId, "_"),
                    key: "skuPureTime_column",
                    align: "center",
                    width: 125,
                    render: (item: RoutingBySkuDataItem) => {
                      if (item?.skusPureTime) {
                        return formatNumber(item.skusPureTime[index]?.value);
                      }
                    },
                  });
                }
              }
            );
          }
        }
      }

      return result;
    };

    let initialColumns: ColumnsType<RoutingBySkuDataItem> = [
      {
        title: t("tableColumn.father"),
        key: "father_column",
        align: "center",
        width: 140,
        onCell: (record: RoutingBySkuDataItem, index: number | undefined) => ({
          rowSpan: getGroupedCellRowSpan(index ?? 0, record, dataItems, [
            "costTypeMapping",
            "father",
          ]),
        }),
        render: (item: RoutingBySkuDataItem) =>
          (item.rowType ?? null) !== null ? item.costTypeMapping : item.father,
      },
      {
        title: t("tableColumn.fatherCode"),
        key: "fatherCode_column",
        align: "center",
        width: 120,
        onCell: (record: RoutingBySkuDataItem, index: number | undefined) => ({
          rowSpan: getGroupedCellRowSpan(index ?? 0, record, dataItems, [
            "costTypeMapping",
            "father",
            "fatherCode",
          ]),
        }),
        render: (item: RoutingBySkuDataItem) => (
          <TextWithLink
            label={item.fatherCode}
            href={item.fatherCode3dSplm21xUrl}
          />
        ),
      },
      {
        title: t("tableColumn.component"),
        key: "component_column",
        width: 100,
        align: "center",
        onCell: (record: RoutingBySkuDataItem, index: number | undefined) => ({
          rowSpan: getGroupedCellRowSpan(index ?? 0, record, dataItems, [
            "costTypeMapping",
            "father",
            "fatherCode",
            "component",
          ]),
        }),
        render: (item: RoutingBySkuDataItem) => item.component,
      },
      {
        title: t("tableColumn.componentCode"),
        key: "componentCode_column",
        align: "center",
        width: 120,
        onCell: (record: RoutingBySkuDataItem, index: number | undefined) => ({
          rowSpan: getGroupedCellRowSpan(index ?? 0, record, dataItems, [
            "costTypeMapping",
            "father",
            "fatherCode",
            "component",
            "componentCode",
          ]),
        }),
        render: (item: RoutingBySkuDataItem) => (
          <TextWithLink
            label={item.componentCode}
            href={item.componentCode3dSplm21xUrl}
          />
        ),
      },
      {
        title: t("tableColumn.operationCodeDescription"),
        key: "operationCodeDescription_column",
        align: "center",
        width: 120,
        render: (item: RoutingBySkuDataItem) => item.operationCodeDescription,
      },
      {
        title: t("tableColumn.dl"),
        key: "dl_column",
        align: "center",
        width: 70,
        render: (item: RoutingBySkuDataItem) => (
          <ValueWithCurrency value={item.dl} currencyCode={item.currencyCode} />
        ),
      },
    ];

    const columns: ColumnsType<RoutingBySkuDataItem> = [
      ...initialColumns,
      ...getDynamicColumns1(),
      ...([
        {
          title: t("tableColumn.pureTime"),
          key: "pureTime_column",
          align: "center",
          width: 80,
          render: (item: RoutingBySkuDataItem) => {
            return formatNumber(item.pureTime);
          },
        },
      ] as ColumnsType<RoutingBySkuDataItem>),
      ...getDynamicColumns2(),
      ...([
        {
          title: t("tableColumn.costCenter"),
          key: "costCenter_column",
          align: "center",
          width: 110,
          render: (item: RoutingBySkuDataItem) => item.costCenter,
        },
        {
          title: t("tableColumn.prodSched"),
          key: "prodSched_column",
          align: "center",
          width: 110,
          render: (item: RoutingBySkuDataItem) => item.prodSched,
        },
      ] as ColumnsType<RoutingBySkuDataItem>),
    ];

    return columns;
  }, [dataItems, t]);

  return {
    getTableColumns: getTableColumns,
  };
}
