import { useEffect, useState } from "react";
import { calculatePropertyAverage } from "src/core/utils/array";
import { TableRowTypeEnum } from "src/enums";
import { ModelSkuSelectionItemData } from "src/types/costExplosion";

export function useModelSkuSelectionItemData(
  dataItems: ModelSkuSelectionItemData[],
  selectedDataItems: ModelSkuSelectionItemData[]
) {
  const [processed, setProcessed] = useState(false);
  const [result, setResult] = useState<ModelSkuSelectionItemData[]>([]);

  const summaryRowKey = "selection-summary-row-1";

  useEffect(() => {
    (async () => {
      if (dataItems.length > 0) {
        let selectedAverages: ModelSkuSelectionItemData[] = [
          {
            sku: summaryRowKey,

            fullCost: calculatePropertyAverage(selectedDataItems, "fullCost"),
            frameFc: calculatePropertyAverage(selectedDataItems, "frameFc"),
            frameDc: calculatePropertyAverage(selectedDataItems, "frameDc"),
            lensFc: calculatePropertyAverage(selectedDataItems, "lensFc"),
            lensDc: calculatePropertyAverage(selectedDataItems, "lensDc"),
            pkgFc: calculatePropertyAverage(selectedDataItems, "pkgFc"),
            pkgDc: calculatePropertyAverage(selectedDataItems, "pkgDc"),
            directCost: calculatePropertyAverage(
              selectedDataItems,
              "directCost"
            ),

            dm: calculatePropertyAverage(selectedDataItems, "dm"),
            dl: calculatePropertyAverage(selectedDataItems, "dl"),
            sc: calculatePropertyAverage(selectedDataItems, "sc"),
            fov: calculatePropertyAverage(selectedDataItems, "fov"),
            fof1: calculatePropertyAverage(selectedDataItems, "fof1"),
            fof2: calculatePropertyAverage(selectedDataItems, "fof2"),
            mo: calculatePropertyAverage(selectedDataItems, "mo"),
            dc: calculatePropertyAverage(selectedDataItems, "dc"),

            currencyCode: selectedDataItems[0]?.currencyCode
              ? selectedDataItems[0].currencyCode
              : dataItems[0]?.currencyCode,
            rowType: TableRowTypeEnum.SUMMARYROW,
          },
        ];

        setResult([...selectedAverages, ...dataItems]);
      } else {
        setResult([]);
      }

      setProcessed(true);
    })();
  }, [dataItems, selectedDataItems]);

  return {
    processed: processed,
    processedData: result,
    summaryRowKey: summaryRowKey,
  };
}
