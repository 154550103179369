import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { calculatePropertySum } from "src/core/utils/array";
import { TableRowTypeEnum } from "src/enums";
import { FatherComparisonItemData } from "src/types/costComparison";

export function useFatherComparisonItemData(
  dataItems: FatherComparisonItemData[]
) {
  const [processingData, setProccessingData] = useState(false);
  const [result, setResult] = useState<FatherComparisonItemData[]>([]);

  const { t } = useTranslation("costExplosionComparison");

  useEffect(() => {
    (async () => {
      if (dataItems.length > 0) {
        setProccessingData(true);

        let finalDataItems: FatherComparisonItemData[] = [
          {
            rowKey: "summary-row-1",

            currencyCode: dataItems[0].currencyCode,
            rowType: TableRowTypeEnum.SUMMARYROW,

            costTypeMapping: t("label.totalCost") ?? "",

            m1FullCost: calculatePropertySum(dataItems, "m1FullCost"),
            m1DirectCost: calculatePropertySum(dataItems, "m1DirectCost"),
            m1Dm: calculatePropertySum(dataItems, "m1Dm"),
            m1Dl: calculatePropertySum(dataItems, "m1Dl"),
            m1Sc: calculatePropertySum(dataItems, "m1Sc"),

            m2FullCost: calculatePropertySum(dataItems, "m2FullCost"),
            m2DirectCost: calculatePropertySum(dataItems, "m2DirectCost"),
            m2Dm: calculatePropertySum(dataItems, "m2Dm"),
            m2Dl: calculatePropertySum(dataItems, "m2Dl"),
            m2Sc: calculatePropertySum(dataItems, "m2Sc"),

            deltaFullCost: calculatePropertySum(dataItems, "deltaFullCost"),
            deltaDirectCost: calculatePropertySum(dataItems, "deltaDirectCost"),
            deltaDm: calculatePropertySum(dataItems, "deltaDm"),
            deltaDl: calculatePropertySum(dataItems, "deltaDl"),
            deltaSc: calculatePropertySum(dataItems, "deltaSc"),
          },
        ];

        dataItems.forEach((item: FatherComparisonItemData, index) => {
          const existingSubSummaryRow = finalDataItems.find(
            (x) =>
              x.rowType === TableRowTypeEnum.SUBSUMMARYROW &&
              x.costTypeMapping === item.costTypeMapping
          );

          if (!existingSubSummaryRow) {
            const recordsToWork = dataItems.filter(
              (x) => x.costTypeMapping === item.costTypeMapping
            );

            finalDataItems.push({
              rowKey: `sub-summary-${index}`,

              costTypeMapping: item.costTypeMapping,

              m1FullCost: calculatePropertySum(recordsToWork, "m1FullCost"),
              m1DirectCost: calculatePropertySum(recordsToWork, "m1DirectCost"),
              m1Dm: calculatePropertySum(recordsToWork, "m1Dm"),
              m1Dl: calculatePropertySum(recordsToWork, "m1Dl"),
              m1Sc: calculatePropertySum(recordsToWork, "m1Sc"),

              m2FullCost: calculatePropertySum(recordsToWork, "m2FullCost"),
              m2DirectCost: calculatePropertySum(recordsToWork, "m2DirectCost"),
              m2Dm: calculatePropertySum(recordsToWork, "m2Dm"),
              m2Dl: calculatePropertySum(recordsToWork, "m2Dl"),
              m2Sc: calculatePropertySum(recordsToWork, "m2Sc"),

              deltaFullCost: calculatePropertySum(
                recordsToWork,
                "deltaFullCost"
              ),
              deltaDirectCost: calculatePropertySum(
                recordsToWork,
                "deltaDirectCost"
              ),
              deltaDm: calculatePropertySum(recordsToWork, "deltaDm"),
              deltaDl: calculatePropertySum(recordsToWork, "deltaDl"),
              deltaSc: calculatePropertySum(recordsToWork, "deltaSc"),

              currencyCode: dataItems[0].currencyCode,
              rowType: TableRowTypeEnum.SUBSUMMARYROW,
            });
          }

          finalDataItems.push({ ...item, rowKey: `row-key-${index}` });
        });

        setResult(finalDataItems);
      } else {
        setResult([]);
      }

      setProccessingData(false);
    })();
  }, [dataItems, t]);

  return {
    processingData: processingData,
    processedData: result,
  };
}
