import { API_BASE_URL } from "src/constants";
import { post } from "src/core/utils/api";
import { DataWithThreshold } from "src/types/common";
import { RoutingsDataItem } from "src/types/costExplosion";

const createRoutingsService = () => {
  const fetchRoutingsDetailDataItems = async (
    accessToken: string,
    modelNumber: string,
    skus: string[]
  ): Promise<DataWithThreshold<RoutingsDataItem[]>> => {
    return await post<DataWithThreshold<RoutingsDataItem[]>>(
      accessToken,
      API_BASE_URL + `/v1/ce/routing/data/detail?modelNumber=${modelNumber}`,
      { values: skus }
    );
  };

  const fetchRoutingsSummaryDataItems = async (
    accessToken: string,
    modelNumber: string,
    skus: string[]
  ): Promise<RoutingsDataItem[]> => {
    return await post<RoutingsDataItem[]>(
      accessToken,
      API_BASE_URL + `/v1/ce/routing/data/summary?modelNumber=${modelNumber}`,
      { values: skus }
    );
  };

  return { fetchRoutingsDetailDataItems, fetchRoutingsSummaryDataItems };
};
export const routingsService = createRoutingsService();
