import { Space, Typography } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CircleWithColor } from "src/core/components/CircleWithColor";
import { formatNumber } from "src/core/utils/format";
import { textWithSuffix } from "src/core/utils/text";
import { MadeInOriginStatsTypeEnum } from "src/enums";
import {
  MadeInOriginCalculationStatsItem,
  MadeInOriginStatsItem,
} from "src/types/priceConfigurator";

type Props = {
  tableTitle: string;
  statsDataItems: MadeInOriginStatsItem[];
  calculationStatsDataItems: MadeInOriginCalculationStatsItem[];
  dataRefreshing?: boolean;
  hideLabelsColumn?: boolean;
};

type MergedStatsDataItem = {
  isCalculationItem?: boolean;
  type?: any;
  typeId?: number;
} & MadeInOriginStatsItem &
  MadeInOriginCalculationStatsItem;

const { Text } = Typography;

export const MadeInOriginStatsDataTable = (props: Props) => {
  const {
    statsDataItems,
    calculationStatsDataItems,
    dataRefreshing,
    hideLabelsColumn,
    tableTitle,
  } = props;

  const [dataItems, setDataItems] = useState<MergedStatsDataItem[]>([]);

  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      if (statsDataItems.length > 0 || calculationStatsDataItems.length > 0) {
        let mergedList: MergedStatsDataItem[] = [];

        calculationStatsDataItems.forEach((item) => {
          mergedList.push({
            ...item,
            isCalculationItem: true,
            type: undefined,
            typeId: item.type,
          });
        });

        statsDataItems.forEach((item) => {
          mergedList.push({
            ...item,
            isCalculationItem: false,
            type: undefined,
            typeId: item.type,
          });
        });

        setDataItems(mergedList);
      }
    })();
  }, [calculationStatsDataItems, statsDataItems]);

  const columns: ColumnsType<MergedStatsDataItem> = [
    {
      title: tableTitle,
      children: [
        {
          title: t("tableColumn.costKoInEuros"),
          key: "calculation_costKoInEuros_column",
          width: 160,
          align: "center",
          render: (item: MergedStatsDataItem) =>
            item.isCalculationItem ? (
              <Text style={{ fontWeight: 600 }}>
                {item.valueInPercents
                  ? textWithSuffix(item.value, "%")
                  : formatNumber(item.value)}
              </Text>
            ) : (
              <Space
                className="ignore-full-width-space"
                align="center"
                style={{
                  textAlign: "center",
                  alignContent: "center",
                  justifyContent: "center",
                  width: "100%",
                  fontWeight: 600,
                }}
              >
                <CircleWithColor color={item.valueIndicator?.color} />
                <Text>{textWithSuffix(item.value, "%")}</Text>
              </Space>
            ),
        },
        {
          title: t("tableColumn.costKoInEurosNewEr"),
          key: "calculation_costKoInEurosNewEr_column",
          width: 160,
          align: "center",
          render: (item: MergedStatsDataItem) =>
            item.isCalculationItem ? (
              <Text style={{ fontWeight: 600 }}>
                {item.valueInPercents
                  ? textWithSuffix(item.valueNewEr, "%")
                  : formatNumber(item.valueNewEr)}
              </Text>
            ) : (
              <Space
                className="ignore-full-width-space"
                align="center"
                style={{
                  textAlign: "center",
                  alignContent: "center",
                  justifyContent: "center",
                  width: "100%",
                  fontWeight: 600,
                }}
              >
                <CircleWithColor color={item.valueNewErIndicator?.color} />
                <Text>{textWithSuffix(item.valueNewEr, "%")}</Text>
              </Space>
            ),
        },
      ],
    },
  ];

  return (
    <React.Fragment>
      <Table
        columns={
          hideLabelsColumn
            ? columns
            : [
                ...[
                  {
                    key: "made_in_origin_calculation_type_column",
                    width: 120,
                    render: (item: MergedStatsDataItem) => (
                      <Text
                        style={{
                          textAlign: "left",
                          fontWeight: 600,
                        }}
                      >
                        {item.isCalculationItem
                          ? t(
                              `label.madeInOriginCalculationStatsType_${item.typeId}`
                            )
                          : `${t(
                              `label.madeInOriginStatsType_${item.typeId}`
                            )} ${
                              item.typeId === MadeInOriginStatsTypeEnum.MADEIN
                                ? `(>${item.warningThresholdValue}%)`
                                : item.typeId ===
                                  MadeInOriginStatsTypeEnum.ORIGIN
                                ? `(<${item.warningThresholdValue}%)`
                                : ``
                            }`}
                      </Text>
                    ),
                  },
                  ...columns,
                ],
              ]
        }
        loading={dataRefreshing}
        dataSource={dataItems}
        pagination={false}
        rowKey={(record) =>
          `${record.isCalculationItem ? "c" : "s"}-${record.typeId}`
        }
        scroll={{ x: "max-content" }}
        bordered={true}
        size={"small"}
      />
    </React.Fragment>
  );
};
