import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { calculatePropertyAverage } from "src/core/utils/array";
import { TableRowTypeEnum } from "src/enums";
import { LensesDataItem } from "src/types/costExplosion";

export function useLensesDetailDataItemData(dataItems: LensesDataItem[]) {
  const [processed, setProcessed] = useState(false);
  const [result, setResult] = useState<LensesDataItem[]>([]);

  const { t } = useTranslation("costExplosionComparison");

  useEffect(() => {
    (async () => {
      if (dataItems.length > 0) {
        let finalDataItems: LensesDataItem[] = [];

        dataItems.forEach((item: LensesDataItem, index) => {
          const existingSubSummaryRow = finalDataItems.find(
            (x) =>
              x.rowType === TableRowTypeEnum.SUBSUMMARYROW &&
              x.costTypeMapping === item.costTypeMapping
          );

          if (!existingSubSummaryRow) {
            const recordsToWork = dataItems.filter(
              (x) => x.costTypeMapping === item.costTypeMapping
            );

            finalDataItems.push({
              rowKey: `sub-summary-${index}`,

              costTypeMapping: item.costTypeMapping,

              fullCost: calculatePropertyAverage(recordsToWork, "fullCost"),

              dm: calculatePropertyAverage(recordsToWork, "dm"),
              dl: calculatePropertyAverage(recordsToWork, "dl"),
              sc: calculatePropertyAverage(recordsToWork, "sc"),
              fov: calculatePropertyAverage(recordsToWork, "fov"),
              fof1: calculatePropertyAverage(recordsToWork, "fof1"),
              fof2: calculatePropertyAverage(recordsToWork, "fof2"),
              mo: calculatePropertyAverage(recordsToWork, "mo"),
              dc: calculatePropertyAverage(recordsToWork, "dc"),

              directCost: calculatePropertyAverage(recordsToWork, "directCost"),
              variableCost: calculatePropertyAverage(
                recordsToWork,
                "variableCost"
              ),
              pureTime: calculatePropertyAverage(recordsToWork, "pureTime"),

              currencyCode: dataItems[0].currencyCode,
              rowType: TableRowTypeEnum.SUBSUMMARYROW,
            });
          }

          finalDataItems.push({ ...item, rowKey: `row-key-${index}` });
        });

        setResult(finalDataItems);
      } else {
        setResult([]);
      }

      setProcessed(true);
    })();
  }, [dataItems, t]);

  return {
    processed: processed,
    processedData: result,
  };
}
