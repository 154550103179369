import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  calculatePropertySum,
  calculateWeightedAverage,
} from "src/core/utils/array";
import { TableRowTypeEnum } from "src/enums";
import { LensesDataItem } from "src/types/costExplosion";

export function useLensesDataItemData(dataItems: LensesDataItem[]) {
  const [processed, setProcessed] = useState(false);
  const [result, setResult] = useState<LensesDataItem[]>([]);

  const { t } = useTranslation("costExplosionComparison");

  useEffect(() => {
    (async () => {
      if (dataItems.length > 0) {
        let finalDataItems: LensesDataItem[] = [];

        dataItems.forEach((item: LensesDataItem, index) => {
          const existingSubSummaryRow = finalDataItems.find(
            (x) =>
              x.rowType === TableRowTypeEnum.SUBSUMMARYROW &&
              x.costTypeMapping === item.costTypeMapping
          );

          if (!existingSubSummaryRow) {
            const recordsToWork = dataItems.filter(
              (x) => x.costTypeMapping === item.costTypeMapping
            );

            finalDataItems.push({
              rowKey: `sub-summary-${index}`,

              costTypeMapping: item.costTypeMapping,

              lensMaterial: item.lensMaterial,
              lensType: item.lensType,

              skuCount: calculatePropertySum(recordsToWork, "skuCount"),

              fullCost: calculateWeightedAverage(
                recordsToWork,
                "fullCost",
                "skuCount"
              ),

              dm: calculateWeightedAverage(recordsToWork, "dm", "skuCount"),
              dl: calculateWeightedAverage(recordsToWork, "dl", "skuCount"),
              sc: calculateWeightedAverage(recordsToWork, "sc", "skuCount"),
              fov: calculateWeightedAverage(recordsToWork, "fov", "skuCount"),
              fof1: calculateWeightedAverage(recordsToWork, "fof1", "skuCount"),
              fof2: calculateWeightedAverage(recordsToWork, "fof2", "skuCount"),
              mo: calculateWeightedAverage(recordsToWork, "mo", "skuCount"),
              dc: calculateWeightedAverage(recordsToWork, "dc", "skuCount"),

              directCost: calculateWeightedAverage(
                recordsToWork,
                "directCost",
                "skuCount"
              ),
              variableCost: calculateWeightedAverage(
                recordsToWork,
                "variableCost",
                "skuCount"
              ),
              pureTime: calculateWeightedAverage(
                recordsToWork,
                "pureTime",
                "skuCount"
              ),

              currencyCode: dataItems[0].currencyCode,
              rowType: TableRowTypeEnum.SUBSUMMARYROW,
            });
          }

          finalDataItems.push({ ...item, rowKey: `row-key-${index}` });
        });

        setResult(finalDataItems);
      } else {
        setResult([]);
      }

      setProcessed(true);
    })();
  }, [dataItems, t]);

  return {
    processed: processed,
    processedData: result,
  };
}
