import { ColumnsType } from "antd/es/table";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { TextWithLink } from "src/core/components/TextWithLink";
import { formatNumber } from "src/core/utils/format";
import { getGroupedCellRowSpan } from "src/core/utils/table";
import { ValueWithCurrency } from "src/modules/ValueWithCurrency/ValueWithCurrency";
import { RoutingsDataItem } from "src/types/costExplosion";

export function useRoutingsSummaryColumns(dataItems: RoutingsDataItem[]) {
  const { t } = useTranslation("costExplosionComparison");

  const getTableColumns = useCallback(() => {
    const columns: ColumnsType<RoutingsDataItem> = [
      {
        title: t("tableColumn.father"),
        key: "father_column",
        align: "center",
        width: 150,
        onCell: (record: RoutingsDataItem, index: number | undefined) => ({
          rowSpan: getGroupedCellRowSpan(index ?? 0, record, dataItems, [
            "costTypeMapping",
            "father",
          ]),
        }),
        render: (item: RoutingsDataItem) =>
          (item.rowType ?? null) !== null ? item.costTypeMapping : item.father,
      },
      {
        title: t("tableColumn.fatherCode"),
        key: "fatherCode_column",
        align: "center",
        width: 120,
        onCell: (record: RoutingsDataItem, index: number | undefined) => ({
          rowSpan: getGroupedCellRowSpan(index ?? 0, record, dataItems, [
            "costTypeMapping",
            "father",
            "fatherCode",
          ]),
        }),
        render: (item: RoutingsDataItem) => (
          <TextWithLink
            label={item.fatherCode}
            href={item.fatherCode3dSplm21xUrl}
          />
        ),
      },
      {
        title: t("tableColumn.component"),
        key: "component_column",
        width: 110,
        align: "center",
        onCell: (record: RoutingsDataItem, index: number | undefined) => ({
          rowSpan: getGroupedCellRowSpan(index ?? 0, record, dataItems, [
            "costTypeMapping",
            "father",
            "fatherCode",
            "component",
          ]),
        }),
        render: (item: RoutingsDataItem) => item.component,
      },
      {
        title: t("tableColumn.componentCode"),
        key: "componentCode_column",
        align: "center",
        width: 100,
        onCell: (record: RoutingsDataItem, index: number | undefined) => ({
          rowSpan: getGroupedCellRowSpan(index ?? 0, record, dataItems, [
            "costTypeMapping",
            "father",
            "fatherCode",
            "component",
            "componentCode",
          ]),
        }),
        render: (item: RoutingsDataItem) => (
          <TextWithLink
            label={item.componentCode}
            href={item.componentCode3dSplm21xUrl}
          />
        ),
      },
      {
        title: t("tableColumn.dl"),
        key: "dl_column",
        align: "center",
        width: 40,
        render: (item: RoutingsDataItem) => (
          <ValueWithCurrency value={item.dl} currencyCode={item.currencyCode} />
        ),
      },
      {
        title: t("tableColumn.pureTime"),
        key: "pureTime_column",
        align: "center",
        width: 40,
        render: (item: RoutingsDataItem) => formatNumber(item.pureTime),
      },
      {
        title: t("tableColumn.prodSched"),
        key: "prodSched_column",
        align: "center",
        width: 60,
        render: (item: RoutingsDataItem) => item.prodSchedule,
      },
    ];

    return columns;
  }, [dataItems, t]);

  return {
    getTableColumns: getTableColumns,
  };
}
