import { API_BASE_URL } from "src/constants";
import { get } from "src/core/utils/api";
import { ModelSkuSelectionItemData } from "src/types/costExplosion";

const createModelSelectionService = () => {
  const fetchModelSelectionOptions = async (
    accessToken: string,
    modelNumber: string
  ): Promise<ModelSkuSelectionItemData[]> => {
    return await get<ModelSkuSelectionItemData[]>(
      accessToken,
      API_BASE_URL + `/v1/ce/skuSelection/data?modelNumber=${modelNumber}`
    );
  };

  return { fetchModelSelectionOptions };
};
export const modelSelectionService = createModelSelectionService();
