import { Collapse, CollapseProps, Space } from "antd";
import Title from "antd/es/typography/Title";
import { useTranslation } from "react-i18next";
import { SelectedModelInfo } from "src/modules/CostExplosionManager/components/SelectedModelInfo/SelectedModelInfo";
import { BomDetailedTable } from "src/modules/CostExplosionManager/tabs/BomTab/components/BomDetailedTable";

export const BomTab = () => {
  const { t } = useTranslation("costExplosionComparison");

  const items: CollapseProps["items"] = [
    {
      key: "bom-detailed",
      label: <Title level={4}>{t("title.bomDetailed")}</Title>,
      children: <BomDetailedTable />,
    },
  ];

  return (
    <Space direction="vertical" style={{ width: "100%" }} size={25}>
      <SelectedModelInfo />

      <Collapse defaultActiveKey={["bom-detailed"]} ghost items={items} />
    </Space>
  );
};
