import { Space, Typography } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { useAuth } from "src/core/hooks/useAuth";
import { recapService } from "src/core/services/priceConfiguratorServices/recapService";
import { RecapSectionTypeEnum } from "src/enums";
import { BomExplosionLensesDetailsTable } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/Recap/components/RecapDataSection/components/BomExplosionRecapContent/components/BomExplosionLensesDetailsTable";
import { BomExplosionRecapTable } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/Recap/components/RecapDataSection/components/BomExplosionRecapContent/components/BomExplosionRecapTable/BomExplosionRecapTable";
import { currentPriceConfiguratorState } from "src/recoil/atoms";
import { RecapSectionData } from "src/types/priceConfigurator";

type Props = { reloadData?: boolean };

const { Title } = Typography;

export const BomExplosionRecapContent = (props: Props) => {
  const { reloadData } = props;

  const [tableLoading, setTableLoading] = useState(false);

  const { t } = useTranslation();
  const [data, setData] = useState<RecapSectionData>();

  const { accessToken, success, authLoaded } = useAuth();

  const currentPriceConfigurator = useRecoilValue(
    currentPriceConfiguratorState
  );

  useEffect(() => {
    (async () => {
      if (authLoaded && success && !reloadData) {
        setTableLoading(true);

        const response = await recapService.getRecapDataByType(
          accessToken,
          currentPriceConfigurator.id,
          currentPriceConfigurator.modelNumber,
          RecapSectionTypeEnum.BOMEXPLOSION
        );

        setData(response);

        setTableLoading(false);
      }
    })();
  }, [
    accessToken,
    authLoaded,
    currentPriceConfigurator.id,
    currentPriceConfigurator.modelNumber,
    reloadData,
    success,
  ]);

  return (
    <Space className="full-width-space" align="baseline" direction="vertical">
      <BomExplosionRecapTable
        dataItems={data?.bomExplosionData ?? []}
        loading={tableLoading}
      />
      <Title level={5} style={{ marginTop: 35 }}>
        {t("label.lensDetails")}
      </Title>
      <BomExplosionLensesDetailsTable
        dataItems={data?.bomLensesExplosionData ?? []}
        loading={tableLoading}
      />
    </Space>
  );
};
