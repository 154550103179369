import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { calculatePropertySum } from "src/core/utils/array";
import { TableRowTypeEnum } from "src/enums";
import { BomComparisonItemData } from "src/types/costComparison";

export function useBomComparisonItemData(dataItems: BomComparisonItemData[]) {
  const [processingData, setProcessingData] = useState(false);
  const [result, setResult] = useState<BomComparisonItemData[]>([]);

  const { t } = useTranslation("costExplosionComparison");

  useEffect(() => {
    (async () => {
      if (dataItems.length > 0) {
        setProcessingData(true);

        let finalDataItems: BomComparisonItemData[] = [
          {
            rowKey: "summary-row-1",

            currencyCode: dataItems[0].currencyCode,
            rowType: TableRowTypeEnum.SUMMARYROW,

            costTypeMapping: t("label.totalCost") ?? "",

            m1Dm: calculatePropertySum(dataItems, "m1Dm"),
            m1PureQuantity: calculatePropertySum(dataItems, "m1PureQuantity"),

            m2Dm: calculatePropertySum(dataItems, "m2Dm"),
            m2PureQuantity: calculatePropertySum(dataItems, "m2PureQuantity"),

            deltaDm: calculatePropertySum(dataItems, "deltaDm"),
          },
        ];

        dataItems.forEach((item: BomComparisonItemData, index) => {
          const existingSubSummaryRow = finalDataItems.find(
            (x) =>
              x.rowType === TableRowTypeEnum.SUBSUMMARYROW &&
              x.costTypeMapping === item.costTypeMapping
          );

          if (!existingSubSummaryRow) {
            const recordsToWork = dataItems.filter(
              (x) => x.costTypeMapping === item.costTypeMapping
            );

            finalDataItems.push({
              rowKey: `sub-summary-${index}`,

              costTypeMapping: item.costTypeMapping,

              m1Dm: calculatePropertySum(recordsToWork, "m1Dm"),
              m1PureQuantity: calculatePropertySum(
                recordsToWork,
                "m1PureQuantity"
              ),

              m2Dm: calculatePropertySum(recordsToWork, "m2Dm"),
              m2PureQuantity: calculatePropertySum(
                recordsToWork,
                "m2PureQuantity"
              ),

              deltaDm: calculatePropertySum(recordsToWork, "deltaDm"),

              currencyCode: dataItems[0].currencyCode,
              rowType: TableRowTypeEnum.SUBSUMMARYROW,
            });
          }

          finalDataItems.push({ ...item, rowKey: `row-key-${index}` });
        });

        setResult(finalDataItems);
      } else {
        setResult([]);
      }

      setProcessingData(false);
    })();
  }, [dataItems, t]);

  return {
    processingData: processingData,
    processedData: result,
  };
}
