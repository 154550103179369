import { API_BASE_URL } from "src/constants";
import { post } from "src/core/utils/api";
import { CostExplosionDataItem } from "src/types/costExplosion";

const createCostExplosionService = () => {
  const fetchDescriptionCodeDataItems = async (
    accessToken: string,
    modelNumber: string,
    skus: string[]
  ): Promise<CostExplosionDataItem[]> => {
    return await post<CostExplosionDataItem[]>(
      accessToken,
      API_BASE_URL +
        `/v1/ce/costExplosion/data/descriptionAndCode?modelNumber=${modelNumber}`,
      { values: skus }
    );
  };

  const fetchDescriptionDataItems = async (
    accessToken: string,
    modelNumber: string,
    skus: string[]
  ): Promise<CostExplosionDataItem[]> => {
    return await post<CostExplosionDataItem[]>(
      accessToken,
      API_BASE_URL +
        `/v1/ce/costExplosion/data/description?modelNumber=${modelNumber}`,
      { values: skus }
    );
  };

  return { fetchDescriptionCodeDataItems, fetchDescriptionDataItems };
};
export const costExplosionService = createCostExplosionService();
