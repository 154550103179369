import { ColumnsType } from "antd/es/table";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { formatNumber } from "src/core/utils/format";
import { getGroupedCellRowSpan } from "src/core/utils/table";
import { TableHeaderCellWithModelNumber } from "src/modules/CostComparisonManager/components/TableHeaderCellWithModelNumber";
import { ValueWithCurrency } from "src/modules/ValueWithCurrency/ValueWithCurrency";
import { LensComparisonItemData } from "src/types/costComparison";

export function useLensesComparisonItemDataColumns(
  dataItems: LensComparisonItemData[]
) {
  const { t } = useTranslation("costExplosionComparison");

  const getTableColumns = useCallback(() => {
    const columns: ColumnsType<LensComparisonItemData> = [
      {
        title: t("tableColumn.lensMaterial"),
        key: "lensMaterial_column",
        align: "center",
        width: 120,
        onCell: (item: LensComparisonItemData, index: number | undefined) => ({
          rowSpan: getGroupedCellRowSpan(index ?? 0, item, dataItems, [
            "costTypeMapping",
            "lensMaterial",
          ]),
        }),
        render: (item: LensComparisonItemData) =>
          (item.rowType ?? null) !== null
            ? item.costTypeMapping
            : item.lensMaterial,
      },
      {
        title: t("tableColumn.lensType"),
        key: "lensType_column",
        align: "center",
        width: 120,
        onCell: (item: LensComparisonItemData, index: number | undefined) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
          rowSpan: getGroupedCellRowSpan(index ?? 0, item, dataItems, [
            "costTypeMapping",
            "lensMaterial",
            "lensType",
          ]),
        }),
        render: (item: LensComparisonItemData) => item.lensType,
      },
      {
        title: t("tableColumn.photochromic"),
        key: "photochromic_column",
        align: "center",
        width: 120,
        onCell: (item: LensComparisonItemData, index: number | undefined) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
        }),
        render: (item: LensComparisonItemData) => item.photochromic,
      },
      {
        title: " ",
        key: "separator1_column",
        width: 1,
        onCell: (item: LensComparisonItemData, index: number | undefined) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell none-padding-cell left-right-border-dashed"
            : "none-padding-cell left-right-border-dashed",
        }),
        render: () => undefined,
      },
      /*-----------------  M1  ----------------------------------------------*/
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.skuCount")}
            number={1}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
          />
        ),
        key: "skuCount_column",
        align: "center",
        width: 70,
        onCell: (item: LensComparisonItemData, index: number | undefined) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
        }),
        render: (item: LensComparisonItemData) => item.m1SkuCount,
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.fullCost")}
            number={1}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
          />
        ),
        key: "fullCost_column",
        align: "center",
        width: 70,
        onCell: (item: LensComparisonItemData) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
        }),
        render: (item: LensComparisonItemData) => (
          <ValueWithCurrency
            value={item.m1FullCost}
            currencyCode={item.currencyCode}
            showSymbolIfNull={"-"}
          />
        ),
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.directCost")}
            number={1}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
          />
        ),
        key: "directCost_column",
        align: "center",
        width: 70,
        onCell: (item: LensComparisonItemData) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
        }),
        render: (item: LensComparisonItemData) => (
          <ValueWithCurrency
            value={item.m1DirectCost}
            currencyCode={item.currencyCode}
            showSymbolIfNull={"-"}
          />
        ),
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.pureTime")}
            number={1}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
          />
        ),
        key: "pureTime_column",
        align: "center",
        width: 70,
        onCell: (item: LensComparisonItemData) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
        }),
        render: (item: LensComparisonItemData) =>
          formatNumber(item.m1PureTime, undefined, false, "-"),
      },
      {
        title: " ",
        key: "separator2_column",
        width: 1,
        onCell: (item: LensComparisonItemData, index: number | undefined) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell none-padding-cell left-right-border-dashed"
            : "none-padding-cell left-right-border-dashed",
        }),
        render: () => undefined,
      },
      /*-----------------  M2  ----------------------------------------------*/
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.skuCount")}
            number={2}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
          />
        ),
        key: "skuCount_column",
        align: "center",
        width: 70,
        onCell: (item: LensComparisonItemData, index: number | undefined) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
        }),
        render: (item: LensComparisonItemData) => item.m2SkuCount,
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.fullCost")}
            number={2}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
          />
        ),
        key: "fullCost_column",
        align: "center",
        width: 70,
        onCell: (item: LensComparisonItemData) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
        }),
        render: (item: LensComparisonItemData) => (
          <ValueWithCurrency
            value={item.m2FullCost}
            currencyCode={item.currencyCode}
            showSymbolIfNull={"-"}
          />
        ),
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.directCost")}
            number={2}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
          />
        ),
        key: "directCost_column",
        align: "center",
        width: 70,
        onCell: (item: LensComparisonItemData) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
        }),
        render: (item: LensComparisonItemData) => (
          <ValueWithCurrency
            value={item.m2DirectCost}
            currencyCode={item.currencyCode}
            showSymbolIfNull={"-"}
          />
        ),
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.pureTime")}
            number={2}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
          />
        ),
        key: "pureTime_column",
        align: "center",
        width: 70,
        onCell: (item: LensComparisonItemData) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
        }),
        render: (item: LensComparisonItemData) =>
          formatNumber(item.m2PureTime, undefined, false, "-"),
      },
      {
        title: " ",
        key: "separator3_column",
        width: 1,
        onCell: (item: LensComparisonItemData, index: number | undefined) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell none-padding-cell left-right-border-dashed"
            : "none-padding-cell left-right-border-dashed",
        }),
        render: () => undefined,
      },
      /*-----------------  M2 - M1  ----------------------------------------------*/
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.fullCost")}
            number={2}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
            compareModelWith={1}
          />
        ),
        key: "fullCost_column",
        align: "center",
        width: 80,
        onCell: (item: LensComparisonItemData, index: number | undefined) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
        }),
        render: (item: LensComparisonItemData) => (
          <ValueWithCurrency
            value={item.deltaFullCost}
            currencyCode={item.currencyCode}
            showSymbolIfNull={"-"}
          />
        ),
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.directCost")}
            number={2}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
            compareModelWith={1}
          />
        ),
        key: "directCost_column",
        align: "center",
        width: 80,
        onCell: (item: LensComparisonItemData, index: number | undefined) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
        }),
        render: (item: LensComparisonItemData) =>
          formatNumber(item.deltaDirectCost, undefined, false, "-"),
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.pureTime")}
            number={2}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
            compareModelWith={1}
          />
        ),
        key: "pureTime_column",
        align: "center",
        width: 80,
        onCell: (item: LensComparisonItemData, index: number | undefined) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
        }),
        render: (item: LensComparisonItemData) =>
          formatNumber(item.deltaPureTime, undefined, false, "-"),
      },
    ];

    return columns;
  }, [dataItems, t]);

  return {
    getTableColumns: getTableColumns,
  };
}
